div.ReactTable {
	border: none;
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.18);
	border-radius: 4px;
	overflow: hidden;
	background: var(--react-table-bg);
	border-color: var(--rt-border) !important;
	.rt-table {
		.rt-thead {
			box-shadow: none;
			position: sticky;
			background: var(--react-table-bg);
			z-index: 10;
			top: 0;
			&.-filters {
				border-bottom: none;
				top: 36px;
			}
			&.-header {
				box-shadow: none;
				z-index: 0;
			}
			.rt-tr {
				flex: 0;
				border-bottom: 1px solid var(--grey-6);
				padding: 0 1.5rem 0 0.5rem;
				.rt-th {
					padding: 0.55rem 0;
					font-size: 0.875rem;
					font-weight: 600;
					color: var(--grey-5);
					text-align: left;
					border-right: none;
					padding-left: 0.25rem;
					display: flex;
					align-items: center;
					border-right: none;
					.rt-resizable-header-content {
						padding: 1px 5px 1px 1px;
						white-space: nowrap;
						//display: flex;  // Commented for ellipsis in table header
						//align-items: center;
					}
					.rt-resizer {
						width: 5px;
						right: 0;
					}
					&.has-filters {
						.rt-resizable-header-content {
							position: relative;
							overflow: visible;
							&:after {
								position: absolute;
								top: -5px;
								left: 0px;
								width: 8px;
								height: 8px;
								background: var(--primary);
								border-radius: 50px;
								content: '';
							}
						}
					}
					&:last-child {
						padding-right: 25px;
					}
				}
			}
		}
		.rt-tbody {
			overflow: unset;
			.rt-tr-group {
				padding: 0 1.5rem 0 0.5rem;
				flex: 0;
				border-bottom: 1px solid var(--grey-7);
				&:hover {
					background: var(--text-2) !important;
				}
				.rt-tr {
					.rt-td {
						padding: 0.5rem 6px 0.5rem 4px;
						border-right: none;
						color: var(--grey-5);
						font-size: 0.875rem;
						display: flex;
						align-items: center;
						&:last-child {
							padding-right: 25px;
						}
					}
				}
				&:last-child {
					border-bottom: 0;
				}
				&:nth-child(even) {
					background: var(--react-table-bg-even);
				}
			}
		}
		.ps__rail-x {
			z-index: 0 !important;
		}
	}
	&.rt-header-bg {
		.rt-thead {
			background: $table-header-bg !important;
		}
	}
	&.cell-overflow-visible {
		.rt-td {
			overflow: visible !important;
		}
	}
	&.no-tr-padding {
		.rt-thead {
			.rt-tr {
				padding: 0;
			}
		}
		.rt-tbody {
			.rt-tr-group {
				padding: 0;
			}
		}
	}
	&.sticky-multiple-header {
		.-headerGroups {
			position: sticky;
			top: 0;
		}
		.-header {
			top: 35.38px;
		}
	}
	.rt-noData {
		background: transparent;
		color: var(--grey-2);
	}
	.rt-expander {
		&:after {
			border-top-color: #666;
		}
	}
	.custom-checkbox {
		min-height: 1rem;
		.custom-control-label {
			width: 1rem;
			height: 1rem;
		}
	}
	.ReactTable {
		padding-bottom: 0 !important;
		position: relative;
		z-index: 0;
		.rt-thead {
			.rt-tr {
				.rt-th.filter-icon {
					padding: 0 !important;
					.rt-resizer {
						display: none !important;
					}
				}
				.rt-th {
					padding: 0.3rem 16px 0.4rem 16px !important;
					.rt-resizable-header-content {
						font-size: 12px;
						padding: 0 5px 0 1px;
					}
				}
			}
		}
		&.custom-checkbox {
			.rt-tr {
				.rt-th {
					padding: 0.3rem 0px 0.4rem 5px !important;
				}
			}
		}
	}
	&.one-column-sticky {
		.rt-thead.-header {
			.rt-tr {
				.rt-th {
					&:first-child {
						position: sticky;
						left: 0;
						z-index: 1;
						background-color: var(--table-header-bg);
					}
				}
			}
		}
		.rt-tbody {
			.rt-tr-group {
				.rt-tr {
					.rt-td {
						&:first-child {
							position: sticky;
							left: 0;
							background-color: var(--react-table-bg);
							z-index: 1;
						}
					}
				}
			}
		}
		.rt-tbody {
			.rt-tr-group {
				&:hover {
					.rt-tr {
						.rt-td {
							background: var(--text-2) !important;
						}
					}
				}
			}
		}
		.rt-tfoot {
			.rt-tr {
				.rt-td {
					&:first-child {
						position: sticky;
						left: 0;
						z-index: 1;
						background-color: var(--table-header-bg);
					}
				}
			}
		}
	}
	&.two-column-sticky {
		.rt-thead {
			.rt-tr {
				.rt-th {
					&:first-child,
					&:nth-child(2) {
						position: sticky;
						left: 0;
						z-index: 1;
						background-color: var(--table-header-bg);
					}
					&:nth-child(2) {
						left: 30px;
					}
				}
			}
		}
		.rt-tbody {
			.rt-tr-group {
				.rt-tr {
					.rt-td {
						&:first-child,
						&:nth-child(2) {
							position: sticky;
							left: 0;
							z-index: 1;
							background-color: var(--react-table-bg);
						}
						&:nth-child(2) {
							left: 30px;
						}
					}
				}
				&:nth-child(even) {
					.rt-td {
						background-color: var(--react-table-bg-even) !important;
					}
				}
			}
		}
		.rt-tbody {
			.rt-tr-group {
				&:hover {
					.rt-tr {
						.rt-td {
							background: var(--text-2) !important;
						}
					}
				}
			}
		}
	}
	&.two-column-sticky-cb {
		.rt-thead {
			.rt-tr {
				.rt-th {
					&:first-child,
					&:nth-child(2) {
						position: sticky;
						left: 0;
						z-index: 1;
						background-color: var(--table-header-bg);
					}
					&:nth-child(2) {
						left: 160px;
					}
				}
			}
		}
		.rt-tbody {
			.rt-tr-group {
				.rt-tr {
					.rt-td {
						&:first-child,
						&:nth-child(2) {
							position: sticky;
							left: 0;
							background-color: var(--react-table-bg);
						}
						&:nth-child(2) {
							left: 160px;
						}
					}
				}
				&:nth-child(even) {
					.rt-td {
						background-color: var(--react-table-bg-even) !important;
					}
				}
			}
		}
		.rt-tbody {
			.rt-tr-group {
				&:hover {
					.rt-tr {
						.rt-td {
							background: var(--text-2) !important;
						}
					}
				}
			}
		}
	}
	&.securityid-sticky {
		.rt-thead {
			.rt-tr {
				.rt-th {
					&:first-child,
					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4),
					&:nth-child(5) {
						position: sticky;
						left: 0;
						z-index: 1;
						background-color: var(--table-header-bg);
					}
					&:nth-child(2) {
						left: 150px;
					}
					&:nth-child(3) {
						left: 300px;
					}
					&:nth-child(4) {
						left: 380px;
					}
					&:nth-child(5) {
						left: 530px;
					}
				}
			}
		}
		.rt-tbody {
			.rt-tr-group {
				.rt-tr {
					.rt-td {
						&:first-child,
						&:nth-child(2),
						&:nth-child(3),
						&:nth-child(4),
						&:nth-child(5) {
							position: sticky;
							left: 0;
							background-color: var(--react-table-bg);
						}
						&:nth-child(2) {
							left: 150px;
						}
						&:nth-child(3) {
							left: 300px;
						}
						&:nth-child(4) {
							left: 380px;
						}
						&:nth-child(5) {
							left: 530px;
						}
					}
				}
				&:nth-child(even) {
					.rt-td {
						background-color: var(--react-table-bg-even) !important;
					}
				}
			}
		}
		.rt-tfoot {
			.rt-tr {
				.rt-td {
					&:first-child,
					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4),
					&:nth-child(5) {
						position: sticky;
						left: 0;
						background-color: var(--react-table-bg);
					}
					&:nth-child(2) {
						left: 150px;
					}
					&:nth-child(3) {
						left: 300px;
					}
					&:nth-child(4) {
						left: 380px;
					}
					&:nth-child(5) {
						left: 530px;
					}
				}
			}
			&:nth-child(even) {
				.rt-td {
					background-color: var(--react-table-bg-even) !important;
				}
			}
		}
		.rt-tbody {
			.rt-tr-group {
				&:hover {
					.rt-tr {
						.rt-td {
							background: var(--text-2) !important;
						}
					}
				}
			}
		}
	}
}
.react-table-header {
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.18);
	border-radius: 4px;
	overflow: hidden;
	border-color: var(--rt-border) !important;
	.header {
		padding: 0.5rem 1rem;
		display: flex;
		justify-content: space-between;
		background: var(--card);
		border-radius: 4px;
		align-items: center;
	}
	.ReactTable {
		box-shadow: none;
		border-radius: 0;
		border: none;
		.rt-thead {
			background: $body-bg;
			border-left: none;
			border-right: none;
			.rt-tr {
				.rt-th.filter-icon {
					padding: 0 !important;
					.rt-resizer {
						display: none !important;
					}
				}
				.rt-th {
					font-weight: 700 !important;
					padding: 0.4rem 16px 0.5rem 16px !important;
				}
			}
		}
		.rt-tbody {
			.rt-tr-group {
				.rt-tr {
					.rt-td {
						padding: 0.4rem 16px 0.5rem 16px !important;
					}
				}
			}
		}
		.ReactTable {
			.rt-tbody {
				.rt-tr {
					.rt-td {
						//padding: 0.2rem 0 0.2rem 0 !important;
					}
				}
			}
		}
	}
}

.sorting-default {
	.rt-resizable-header-content {
		padding-right: 10px !important;
		position: relative;
		&:after,
		&:before {
			content: '';
			width: 6px;
			height: 4px;
			position: absolute;
			right: 0px;
			top: 5px;
			bottom: 0;
			margin: auto;
		}
		&:before {
			background: url(../svg/sorting-up.svg) no-repeat;
			margin-top: 0px;
		}
		&:after {
			background: url(../svg/sorting-down.svg) no-repeat;
			margin-top: 7px;
		}
	}
	&.reactTableSortAsc {
		.rt-resizable-header-content {
			&:before {
				background: url(../svg/sorting-up-inactive.svg) no-repeat;
			}
		}
	}
	&.reactTableSortDesc {
		.rt-resizable-header-content {
			&:after {
				background: url(../svg/sorting-down-inactive.svg) no-repeat;
			}
		}
	}
}

.th-align-right {
	.rt-resizable-header-content {
		width: 100%;
		text-align: right;
		margin-right: 0.8rem;
	}
}

.bg-blue {
	background: var(--bg-blue);
}
.bg-yellow {
	background: var(--bg-yellow);
}
.bg-cream {
	background: var(--bg-cream);
}
.totalTxt {
	p {
		font-size: 0.875rem;
		color: var(--grey-5);
		font-weight: bold;
		&:first-child {
			font-size: 0.75rem;
			color: var(--grey-4);
			font-weight: 600;
		}
	}
}

.ReactTable.inv-optimization-table {
	.rt-thead {
		&.-headerGroups {
			z-index: 100;
			.inventory-text {
				overflow: visible;
			}
		}
	}
}

.rt-th.filter-icon {
	.rt-resizable-header-content::after,
	.rt-resizable-header-content::before {
		visibility: hidden;
	}
}

// font-size
// .f-9 { font-size: 0.5625rem !important; })
$font-size-list: 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 24, 26, 28, 30, 36, 48, 96;
@each $font-size in $font-size-list {
	.f-#{$font-size} {
		font-size: #{$font-size/16}rem !important;
	}
}

// cursor
// .cursor-default { cursor: default !important; }
$cursor-list: default, help, not-allowed, pointer;
@each $cursor in $cursor-list {
	.cursor-#{$cursor} {
		cursor: #{$cursor} !important;
	}
}

// height-percentage
// .h-0 { height: 0% !important; }
$height-percentage-list: (
	'0': 0%,
	'20': 20%,
	'40': 40%,
	'58': 58%,
	'60': 60%,
	'72': 72%,
	'80': 80%,
	'88': 88%,
	'90': 90%,
	'100': 100%,
);
@each $height-percentage-label, $height-percentage in $height-percentage-list {
	.h-#{$height-percentage-label} {
		height: #{$height-percentage} !important;
	}
}

// fix height
// .fix-h-25px { height: 25px !important; min-height: 25px !important; max-height: 25px !important; }
$height-list: 25px, 30px, 35px, 67px, 150px, 200px, 250px, 271px, 300px, 350px, 400px;
@each $height in $height-list {
	.fix-h-#{$height} {
		height: #{$height} !important;
		min-height: #{$height} !important;
		max-height: #{$height} !important;
	}
}

// height && vertical-height
// .h-max-content { height: max-content !important; }
$vertical-height-list: max-content, 50vh, 65vh, 90vh, 0px, 30px, 48px, 170px, 190px, 200px, 210px, 214px, 250px, 288px,
	320px, 370px, 378px;
@each $vertical-height in $vertical-height-list {
	.h-#{$vertical-height} {
		height: #{$vertical-height} !important;
	}
}

// min-height
// .min-h-50vh { min-height: 50vh !important; }
$min-height-list: 50vh, 54vh, 55vh, 60vh, 42px, 80px, 148px, 240px, 250px;
@each $min-height in $min-height-list {
	.min-h-#{$min-height} {
		min-height: #{$min-height} !important;
	}
}

// max-height
// .max-h-20px { max-height: 20px !important; }
$max-height-list: 20px, 28px, 155px, 180px, 200px, 265px, 300px, 470px;
@each $max-height in $max-height-list {
	.max-h-#{$max-height} {
		max-height: #{$max-height} !important;
	}
}

// width (percentage)
// .w-20 { width: 20% !important; }
$width-percentage-list: (
	'10': 10%,
	'18': 18%,
	'20': 20%,
	'25': 25%,
	'30': 30%,
	'33': 33%,
	'40': 40%,
	'45': 45%,
	'50': 50%,
	'60': 60%,
	'70': 70%,
	'75': 75%,
	'80': 80%,
	'90': 90%,
	'100': 100%,
);
@each $width-percentage-label, $width-percentage in $width-percentage-list {
	.w-#{$width-percentage-label} {
		width: #{$width-percentage} !important;
		max-width: #{$width-percentage} !important;
	}
}

// width
// .w-32px { width: 32px !important; }
$width-list: 32px, 40px, 60px, 70px, 120px, 135px, 150px, 180px, 200px, 220px, 230px, 240px, 270px, 300px, 350px, 400px,
	700px;
@each $width in $width-list {
	.w-#{$width} {
		width: #{$width} !important;
	}
}

// min-width
// .min-w-100px { min-width: 100px !important; }
$min-width-list: 100px, 120px, 140px, 180px, 210px, 220px, 230px, 250px, 300px, 400px, 500px, 600px;
@each $min-width in $min-width-list {
	.min-w-#{$min-width} {
		min-width: #{$min-width} !important;
	}
}

// max-width
// .max-w-65px { max-width: 65px !important; }
$max-width-list: 65px, 75px, 100px, 120px, 124px, 160px, 220px, 260px, 300px, 320px, 450px;
@each $max-width in $max-width-list {
	.max-w-#{$max-width} {
		max-width: #{$max-width} !important;
	}
}

// margin
// .m-10 {  margin: 10px !important; }
$margin-list: (
	'10': 10px,
	'20': 20px,
);
@each $margin-label, $margin in $margin-list {
	.m-#{$margin-label} {
		margin: #{$margin} !important;
	}
}

// margin-top
// .mt-12 { margin-top: 12px !important; }
$margin-top-list: (
	'12': 12px,
	'30': 30px,
	'65': 65px,
);
@each $margin-top-label, $margin-top in $margin-top-list {
	.mt-#{$margin-top-label} {
		margin-top: #{$margin-top} !important;
	}
}
.mt-n2 {
	margin-top: -2px !important;
}

// margin-bottom
// .mb-6 { margin-bottom: 6px !important; }
$margin-bottom-list: (
	'6': 6px,
	'11': 11px,
	'12': 12px,
	'20': 20px,
	'29': 29px,
	'30': 30px,
	'37': 37px,
	'50': 50px,
);
@each $margin-bottom-label, $margin-bottom in $margin-bottom-list {
	.mb-#{$margin-bottom-label} {
		margin-bottom: #{$margin-bottom} !important;
	}
}
.mb-n5 {
	margin-bottom: -5px;
}

// margin-left
// .ml-7 { margin-left: 7px !important; }
$margin-left-list: (
	'7': 7px,
	'10': 10px,
	'13': 13px,
	'18': 18px,
	'20': 20px,
	'22': 22px,
	'30': 30px,
	'130': 130px,
	'136': 136px,
);
@each $margin-left-label, $margin-left in $margin-left-list {
	.ml-#{$margin-left-label} {
		margin-left: #{$margin-left} !important;
	}
}
.ml-n10 {
	margin-left: -10px !important;
}

// margin-right
// .mr-5 { margin-right: 5px !important; }
$margin-right-list: (
	'5': 5px,
	'8': 8px,
	'10': 10px,
	'18': 18px,
	'20': 20px,
	'30': 30px,
	'50': 50px,
);
@each $margin-right-label, $margin-right in $margin-right-list {
	.mr-#{$margin-right-label} {
		margin-right: #{$margin-right} !important;
	}
}

// padding
// .p-10 { padding: 10px !important; }
$padding-list: (
	'10': 10px,
	'12': 12px,
	'20': 20px,
);
@each $padding-label, $padding in $padding-list {
	.p-#{$padding-label} {
		padding: #{$padding} !important;
	}
}

// padding-horizontal
// .px-10 { padding-left: 10px !important; padding-right: 10px !important; }
$padding-horiztonal-list: (
	'10': 10px,
	'16': 16px,
	'22': 22px,
	'28': 28px,
	'30': 30px,
	'40': 40px,
	'50': 50px,
	'53': 53px,
);
@each $padding-horiztonal-label, $padding-horiztonal in $padding-horiztonal-list {
	.px-#{$padding-horiztonal-label} {
		padding-left: #{$padding-horiztonal} !important;
		padding-right: #{$padding-horiztonal} !important;
	}
}

// padding-vertical
// .py-10 {padding-top: 10px !important; padding-bottom: 10px !important;}
$padding-vertical-list: (
	'5': 5px,
	'10': 10px,
	'17': 17px,
	'22': 22px,
	'30': 30px,
);
@each $padding-vertical-label, $padding-vertical in $padding-vertical-list {
	.py-#{$padding-vertical-label} {
		padding-top: #{$padding-vertical} !important;
		padding-bottom: #{$padding-vertical} !important;
	}
}

// padding-top
// .pt-12 { padding-top: 12px !important; }
$padding-top-list: (
	'12': 12px,
	'18': 18px,
	'20': 20px,
	'26': 26px,
);
@each $padding-top-label, $padding-top in $padding-top-list {
	.pt-#{$padding-top-label} {
		padding-top: #{$padding-top} !important;
	}
}

// padding-bottom
// .pb-10 { padding-bottom: 10px !important; }
$padding-bottom-list: (
	'10': 10px,
	'12': 12px,
	'20': 20px,
	'24': 24px,
	'30': 30px,
	'50': 50px,
);
@each $padding-bottom-label, $padding-bottom in $padding-bottom-list {
	.pb-#{$padding-bottom-label} {
		padding-bottom: #{$padding-bottom} !important;
	}
}

// padding-left
// .pl-6 { padding-left: 6px !important; }
$padding-left-list: (
	'6': 6px,
	'12': 12px,
	'18': 18px,
	'20': 20px,
	'22': 22px,
	'28': 28px,
	'30': 30px,
	'51': 51px,
);
@each $padding-left-label, $padding-left in $padding-left-list {
	.pl-#{$padding-left-label} {
		padding-left: #{$padding-left} !important;
	}
}

// padding-right
// .pr-6 { padding-right: 6px !important; }
$padding-right-list: (
	'6': 6px,
	'10': 10px,
	'15': 15px,
	'20': 20px,
	'28': 28px,
	'30': 30px,
	'50': 50px,
	'65': 65px,
	'98': 98px,
);
@each $padding-right-label, $padding-right in $padding-right-list {
	.pr-#{$padding-right-label} {
		padding-right: #{$padding-right} !important;
	}
}

// text-color
// .text-1 { color: #e3dcdc !important; }
$color-list: (
	'1': --text-1,
	'badge-rajah': --badge-rajah,
	'badge-orange': --badge-orange,
	'belize': --badge-belize,
	'blue': --badge-blue,
	'checkmark-green': --checkmark-green,
	'custom-control-checked': --custom-control-checked,
	'dark': --badge-dark,
	'flamingo': --badge-flamingo,
	'green': --badge-green,
	'maitai': --badge-maitai,
	'black': --black,
	'grey-1': --grey-1,
	'grey-2': --grey-2,
	'grey-3': --grey-3,
	'grey-4': --grey-4,
	'grey-5': --grey-5,
	'grey-6': --grey-6,
	'grey-7': --grey-7,
	'grey-8': --grey-8,
	'grey-9': --grey-9,
	'grey-10': --grey-10,
	'grey-11': --grey-11,
	'grey-12': --grey-12,
	'grey-13': --grey-13,
	'grey-14': --grey-14,
	'grey-15': --grey-15,
	'green-menu-config': --green-menu-config,
	'light-grey': --header-content-color,
	'link': --link-color,
	'nephritis': --badge-nephritis,
	'orange': --badge-orange,
	'pastel': --badge-pastel,
	'purple': --badge-purple,
	'purple-menu-config': --purple-menu-config,
	'rajah': --badge-rajah,
	'white': --white,
	'yellow': --badge-yellow,
);
@each $color-label, $color in $color-list {
	.text-#{$color-label} {
		color: var(#{$color}) !important;

		@if $color-label == 'link' {
			cursor: pointer !important;
		}
	}
}

// background
// .bf-danger { background: #e84033 !important; }
$background-list: (
	'badge-rajah': --badge-rajah,
	'badge-orange': --badge-orange,
	'belize': --badge-belize,
	'light-blue': --badge-blue,
	'danger': --danger-3,
	'dark': --badge-dark,
	'flamingo': --badge-flamingo,
	'green': --badge-green,
	'maitai': --badge-maitai,
	'grey-7': --grey-7,
	'grey-12': --grey-12,
	'info-yellow': --info-yellow,
	'order-id': --orderId,
	'nephritis': --badge-nephritis,
	'orange': --badge-orange,
	'red-1': --red-1,
	'pastel': --badge-pastel,
	'purple': --badge-purple,
	'rajah': --badge-rajah,
	'success': --green-3,
	'summary': --react-table-bg-even,
	'error': --error-bg,
);
@each $background-label, $background in $background-list {
	.bg-#{$background-label} {
		background: var(#{$background}) !important;
	}
}

// font-weight
// .font-weight-400 { font-weight: 400 !important; }
$font-weight-list: 400, 600;
@each $font-weight in $font-weight-list {
	.font-weight-#{$font-weight} {
		font-weight: #{$font-weight} !important;
	}
}

// border-radius
// .border-4 { border-radius: 1rem !important; }
$border-radius-list: 0, 4;
@each $border-radius in $border-radius-list {
	.border-#{$border-radius} {
		border-radius: #{$border-radius/16}rem !important;
	}
}
.border-none {
	border: 0 !important;
}

// line-height
// .lh-normal { line-height: normalpx !important; }
$line-height-list: normal, 0, 1, 10, 16, 18, 20, 21, 23, 24, 25, 26, 35;
@each $line-height in $line-height-list {
	.lh-#{$line-height} {
		line-height: #{$line-height}px !important;
	}
}

// other
.info-yellow-flash {
	animation: background-flash 3s ease;
}
@keyframes background-flash {
	0% {
		background: var(--info-yellow);
	}
	100% {
		background: var(--white);
	}
}

.box-shadow-none {
	box-shadow: none !important;
}
.outline-none {
	outline: none !important;
}

.overflow-ellipsis {
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.border-dashed {
	border: 2px dashed var(--secondary);
}
.border-right-dashed {
	border-right: 1px dashed var(--border-color);
}
.border-bottom-white {
	border-bottom: 1px solid var(--white);
}
.border-bottom-solid-red {
	border-bottom: 2px solid #f00 !important;
}

.scale-d6 svg {
	transform: scale(0.6);
}

.l-200 {
	left: 200px;
}

.flex-basis-cover {
	flex-basis: 100% !important;
}
.flex-1-0-auto {
	flex: 1 0 auto !important;
}
.justinfy-content-space-around {
	justify-content: space-around;
}
.align-item-flex-end {
	align-items: flex-end;
}

.zi-11112 {
	z-index: 11112 !important;
}

.white-space-nowrap {
	white-space: nowrap;
}
.white-space-normal {
	white-space: normal !important;
}

.rotate-180 {
	transform: rotate(180deg);
}

.overflow-x-hidden {
	overflow-x: hidden !important;
}
.overflow-x-auto {
	overflow-x: hidden;
	overflow-y: auto;
}
.overflow-y-auto {
	overflow-y: auto !important;
}

.strike-through {
	text-decoration: line-through;
}

.rotate-90 {
	transform: rotate(90deg);
}

.smooth-transition {
	transition: 300ms linear all;
}

.right-nav {
	max-width: 50px;
	width: 100%;
	background: var(--nav-bg);
	transition: 0.3s max-width ease;
	display: flex;
	// position: relative;
	flex-direction: column;
	position: absolute;
	z-index: 1;
	bottom: 0;
	top: 74px;

	.right-menu-heading {
		padding: 30px 10px 23px 19px;
		font-size: 20px;
		color: $white;
		// background-color: #363745;
		width: 225px;
	}
	.menu-items-list {
		width: 225px;
		svg {
			width: 28px !important;
			color: var(--nav-item-icon-color);
		}
	}
	&.active {
		max-width: 225px;
		overflow-x: hidden;
		.logo-wrapper {
			padding-left: 30px !important;
		}
		.finoptsys-menu-logo {
			display: none !important;
		}
		.finoptsys-menu-logo-horizontal {
			display: block;
			padding: 12px;
		}

		.nav-items-wrapper {
			overflow-y: auto;
			width: 225px;
			overflow-x: hidden;
			.nav-item {
				// padding-left: 2.1875rem;
				&.open-burger {
					padding: 10px 15px 10px 12px;
					border-radius: 50%;
					background-color: var(--navigation-burger-bg-color);
				}
				&.open-burger {
					margin: 0;
					position: absolute;
					left: -43px;
					margin-top: 28px;
					z-index: 10;
					// svg {
					//     transform: rotate(180deg);
					// }
				}
				a {
					display: inline;
				}
				&.has-submenu {
					& + .submenu {
						.sub-item {
							padding: 0.5rem 0 0.5rem 2.2rem;
						}
					}
				}
			}
		}
		.nav-currency {
			padding: 8px 0 8px 33px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			.label {
				display: inline-block;
				padding-top: 2px;
			}
		}
		.user-detail {
			padding: 16px 14px;
			.media {
				.media-right {
					display: inline-block;
				}
			}
		}
	}
	.nav-items-wrapper {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		overflow: auto;
		overflow-x: hidden;
		.logo-wrapper {
			min-height: 80px;
			justify-content: left;
			padding-left: 20px;
			background: var(--card);
			box-shadow: 0px 0px 6px rgba(112, 112, 112, 0.25);
			display: flex;
			align-items: center;
			transition: 0.5s all ease;
		}
		.nav-item {
			transition: 0.5s all ease;
			color: var(--nav-color);
			cursor: pointer;
			transition: 0.3s all ease;
			padding: 10px 13px;
			// margin: 0 15px 0 17px;
			// border-bottom: 1px solid #444444;
			position: relative;
			display: flex;
			white-space: nowrap;
			align-items: center;
			span {
			}
			.nav-link-label {
				text-transform: uppercase;
				font-weight: 600;
				max-width: 170px;
			}
			.nav-arrow {
				position: absolute;
				right: 1rem;
				top: 0.3rem;
				svg {
					transform: rotate(270deg);
					width: 22px;
				}
				svg {
				}
			}
			&.open-burger {
				border-radius: 50%;
				background-color: var(--navigation-burger-bg-color);
				margin: 0;
				position: absolute;
				margin-top: 28px;
				z-index: 10;
				color: #141414;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 12px !important;
				width: 30px;
				height: 30px;
				svg {
					transform: rotate(0deg);
					display: flex;
				}
			}
			a {
				color: inherit;
				font-size: 0.875rem;
				padding-left: 0.5675rem;
				cursor: inherit;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			&:hover {
				color: var(--nav-hover);
			}
			&.disabled {
				color: var(--grey-4);
				cursor: default;
			}
			&.active {
				color: var(--left-nav-active-color);
				background: var(--left-nav-active-bg);
			}
			&.has-submenu {
				background: url(../svg/nav-arrow-down.svg) no-repeat 95% 55%;
				&.active {
					background: url(../svg/nav-arrow-up.svg) no-repeat 95% 55%;
					position: relative;
					border-bottom: 0;
					&:before {
						border-left: 5px solid var(--primary);
						position: absolute;
						left: 0;
						height: 100%;
					}
				}
				& + .submenu {
					max-height: 0;
					overflow: hidden;
					padding: 0;
					margin: 0;
					white-space: normal;
					display: block;
					border-bottom: 0;
					.sub-item {
						padding: 0.5rem 0 0.5rem 2.2rem;
						font-size: 15px;
						color: var(--nav-sub-item-color);
						white-space: nowrap;
						transition: 0.5s all;
						&.active {
							background: var(--primary);
							color: var(--nav-submenu-active-color);
						}
						&:last-child {
							border-bottom: none;
						}
					}
					&.active {
						max-height: 500px;
					}
				}
			}
		}
	}
	.nav-currency {
		color: #e6e6e6;
		font-size: 0.875rem;
		display: flex;
		align-items: center;
		padding: 8px 0 8px 20px;
		background: var(--nav-user);
		justify-content: left;
		max-height: 36px;
		.label {
		}
		.dropdown {
			.dropdown-toggle {
				box-shadow: none;
				border: none;
				width: auto;
				color: var(--white);
				padding: 0 25px 0 4px;
				font-size: 0.875rem;
				&:after {
					color: inherit;
					background: url(../svg/dropdown-arrow-white.svg) no-repeat;
				}
			}
			.dropdown-menu {
				min-width: 5rem;
				.dropdown-item {
					padding: 0.25rem 1rem;
				}
			}
		}
	}
	.user-detail {
		cursor: pointer;
		background: var(--nav-bg);
		padding: 1rem 12px;
		border-top: 1px solid var(--border-color);
		.user-name {
			max-width: 80px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.media {
			img {
				width: 36px;
				height: 36px;
			}
			.media-left {
				padding-right: 0.4rem;
			}
			.media-right {
				display: none;
			}
		}
	}
	.profile-options {
		position: absolute;
		right: -185px;
		bottom: 206px;
		width: 175px;
		.dropdown-menu {
			a {
				display: flex;
				align-items: center;
				padding: 0.5rem 1.25rem;
				color: var(--grey-10);
				font-size: 0.875rem;
				&:hover {
					background: var(--text-2);
				}
				span {
					padding-right: 0.5rem;
					display: flex;
					//color: var(--grey-4);
				}
			}
		}
	}
}

#profile-image {
	height: 22px;
	width: 22px;
	border-radius: 50%;
	font-size: 12px;
	font-weight: bold;
	text-align: center;
	background: var(--nav-profile-bg);
	display: flex;
	align-items: center;
	justify-content: center;
}

.pf-upload-userinitials {
	height: 33px;
	width: 33px;
	padding: 2px 0;
	border-radius: 18px;
	border: 1px solid var(--nav-user);
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	background: #fff;
}

.menu-icon-wrapper {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
}

/* Tooltip text */

.menu-icon-wrapper {
	.menu-icon-text {
		opacity: 0;
		padding: 4px 10px;
		transition: opacity 0.2s ease-in-out;
		background-color: var(--primary);
		color: #fff;
		box-shadow: 0 0 10px #fff;
		text-align: center;
		border-radius: 6px;
		position: fixed;
		left: 80px;
		z-index: 1;
		visibility: hidden;
		&:before {
			content: '';
			position: absolute;
			left: -8px;
			top: 50%;
			transform: translateY(-50%);
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 4px 8px 4px 0;
			border-color: transparent var(--primary) transparent transparent;
		}
	}
	.nav-item:hover + .menu-icon-text {
		visibility: visible;
		z-index: 1059;
		opacity: 1;
		transition: opacity 0.2s ease-in-out;
	}
}
.finoptsys-menu-logo-horizontal {
	display: none;
}
.wide-profile-options {
	position: absolute;
	right: 38px;
	width: 175px;
	.dropdown-item {
		display: flex;
		align-items: center;
		span {
			padding-right: 0.5rem;
			display: flex;
			//color: var(--grey-4);
		}
	}
}

.four-option {
	bottom: 220px !important;
}
.five-option {
	bottom: 260px !important;
}

.menu-option-1 {
	bottom: 120px !important;
}
.menu-option-2 {
	bottom: 160px !important;
}
.menu-option-3 {
	bottom: 200px !important;
}
.menu-option-4 {
	bottom: 220px !important;
}
.menu-option-5 {
	bottom: 260px !important;
}
.menu-option-6 {
	bottom: 295px !important;
}
.menu-option-7 {
	bottom: 330px !important;
}

.submenu {
	&.active {
		background: transparent !important;
	}
}
.beta-badge {
	background-color: var(--beta-ribbon-bg);
	color: var(--beta-ribbon-color);
}
.label-notification-collapsed {
	right: 178px;
	top: -9px;
	border-radius: 50%;
	height: 22px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.custom-async-menu {
	top: -290px !important;
}

.custom-tooltip:last-child {
	margin-bottom: 140px;
}

.custom-tooltip > span:nth-child(3) {
	display: none;
	position: absolute;
	left: 5px;
	overflow: hidden;
}

.custom-tooltip:hover > span:nth-child(3) {
	display: block;
	transition-duration: 2s;
}

.w-custom-tooltip {
	width: calc(100% - 12px);
}

.custom-title > span:first-child {
	display: none;
	position: absolute;
	bottom: 55px;
	left: 120px;
	z-index: 10001;
	padding: 10px;
	margin-right: 12px;
	font-size: 0.825rem;
	background: var(--dropdown-menu-bg);
	color: var(--dropdown-item-color);
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
	overflow: hidden;
}

.custom-title:hover > span:first-child {
	display: block;
	transition-duration: 2s;
}

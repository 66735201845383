.preview-box {
	padding: 20px;
	z-index: 1;

	.preview-inner {
		width: 830px;
		margin: 0 auto;
	}
	.preview-body {
		ul {
			li {
				.value {
					border-right: 1px solid var(--border-color);
				}
				&:nth-child(3n) {
					.value {
						border-right: 0;
					}
					.label-field {
						padding-left: 30px;
					}
				}
				&:nth-child(3n + 2) {
					.label-field {
						padding-left: 30px;
					}
				}
			}
		}
	}
}

.trade-security-input > .rbt {
	width: 100%;
}

.preview-open {
	.modal-content {
		overflow: hidden;
		border: 1px solid rgba(0, 0, 0, 0.6);
	}
	.modal-header {
		border-bottom: 1px solid rgba(0, 0, 0, 0.6);
	}
	.modal-body,
	.modal-header {
		position: relative;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background-color: rgba(0, 0, 0, 0.5);
			z-index: 0;
		}
	}
}

.trade-label {
	.popover-info-icon {
		bottom: 0;
	}
}

.trade-table-tooltip {
	.tooltip-inner {
		max-width: 400px;
		font-size: 13px;
	}
}

.locate-inventory-popover {
	border: 1px solid;
	width: 300px;
	padding: 16px !important;
	font-size: 13px;
}

.trade-security-input {
	.form-control {
		z-index: 1;
	}
	svg {
		position: absolute;
		right: 8px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 0;
	}
}
.input_error {
	border: solid 1px red !important;
}

.accordion-component {
	.card {
		border: 1px solid var(--accordion-border-color);
		box-shadow: 0px 0px 4px #0000004d !important;
		border-radius: 2px;
		background-color: var(--accordion-bg-color);
		margin-bottom: 10px;
		&-header {
			padding: 13px 24px !important;
			line-height: 1;
			border-bottom: 1px solid var(--accordion-border-color);
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 49px;
			max-height: 49px;
		}
		&.show {
			border: 1px solid var(--accordion-open-border-color);
			background-color: var(--accordion-open-bg-color);
		}
	}
	> .card {
		&:first-of-type {
			border-bottom: 1px solid var(--accordion-border-color);
			border-bottom-right-radius: 2px;
			border-bottom-left-radius: 2px;
		}
		&:not(:first-of-type) {
			&:not(:last-of-type) {
				border-bottom: 1px solid var(--accordion-border-color);
				border-bottom-right-radius: 2px;
				border-bottom-left-radius: 2px;
			}
		}
	}
	.ag-react-container {
		max-width: 150px;
		width: 150px;
		display: flex;
	}
}
.repo-text {
	&:after {
		color: $white;
		padding-left: 20px;
		margin-right: 20px;
		content: '';
		border-right: 1px solid var(--grey-5);
		height: 100%;
	}
}
.updated-text {
	display: flex;
	// &:after {
	// 	color: $white;
	// 	padding-left: 20px;
	// 	margin-right: 20px;
	// 	content: '';
	// 	border-right: 1px solid var(--grey-5);
	// }
	&:before {
		color: $white;
		padding-left: 20px;
		margin-right: 20px;
		content: '';
		border-right: 1px solid var(--grey-5);
	}
}

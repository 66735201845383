$colors: (
	green: #509364,
	yellow: #bc975a,
	red: #b74238,
	grey: #a4a1bc,
	teal: #64a8b4,
	turquoise: #57bab3,
	blue: #2980b9,
	dark-green: #337f0a,
	dark-yellow: #ffff00,
	dark-gray: #939592,
);

.status-rounded {
	display: inline-block;
	line-height: 1;
}

@each $key, $value in $colors {
	.status-#{$key} {
		color: $value !important;
	}
}

.sbl-ticker-result {
	color: #4f98e6;
}
.blue-compact-btn {
	background: linear-gradient(90deg, #004080 0%, #1c86ee 100%);
	color: #fff;
	border: none;
	padding: 3px 10px;
	border-radius: 3px;
	cursor: pointer;
	font-size: 12px;
	float: right;
}
.blue-compact-btn:hover {
	background: linear-gradient(90deg, #005a9e 0%, #1e90ff 100%);
}

/**********Scroll Bar accross MA**************/
::-webkit-scrollbar {
	width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(112, 112, 112, 0.8);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(112, 112, 112, 0.8);
}
.sbl-ticker-card {
	display: flex;
	flex-wrap: wrap; /* Allow wrapping for multi-line layout */
	gap: 5px; /* Space between sections */
	background: #2e2e40; /* Overall background */
	padding: 5px;
	border: 1px solid #2e2e40;
	border-radius: 4px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.sbl-ticker-section {
	display: flex; /* Arrange title and value horizontally */
	align-items: center; /* Vertically align the text */
	justify-content: flex-start; /* Ensure items are aligned to the left */
	gap: 4px; /* Space between title and value */
	flex: 1 1 19%;
	min-width: 200px;
	text-align: left;
	padding: 4px;
}

.sbl-ticker-title {
	font-size: 14px;
	color: #aaaaaa; /* Muted light color for titles */
	// font-weight: bold;
	margin: 0; /* Remove default margin */
}

.sbl-ticker-value {
	font-size: 14px;
	color: #ffffff; /* Bright color for values */
	font-weight: normal;
	margin: 0; /* Remove default margin */
}

.sbl-ticker-card:hover {
	border-color: #505065; /* Slightly brighter border on hover */
	box-shadow: 0 6px 8px rgba(0, 0, 0, 0.7);
}

/* General Header Styling */
.header-container-sbl {
	padding: 16px;
	color: #e0e0e0; /* Soft white for text */
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	border-radius: 4px 4px 0 0; /* Rounded corners for the top */
}

/* Order Card Header */
.order-card-header {
	background: #3a3a50; /* Darker grey for card headers */
	color: #dcdcdc; /* Light grey text */
	font-size: 16px;
	font-weight: 600;
	padding: 8px 16px;
	border-bottom: 1px solid #4d4d4d; /* Subtle border for separation */
	border-radius: 4px 4px 0 0;
	text-align: left;
}

/* Highlighted Header for Active Elements */
.active-header {
	background: linear-gradient(90deg, #004080 0%, #1c86ee 100%); /* Blue gradient */
	color: #fff; /* White text */
	font-weight: bold;
	font-size: 16px;
	padding: 10px 16px;
	border-radius: 4px 4px 0 0;
	text-align: center;
}

/* Compact Button (Blue Gradient) */
.blue-compact-btn {
	background: linear-gradient(90deg, #004080 0%, #1c86ee 100%);
	color: #fff;
	border: none;
	padding: 3px 10px;
	border-radius: 3px;
	cursor: pointer;
	font-size: 12px;
	float: right;
}

.blue-compact-btn:hover {
	background: linear-gradient(90deg, #005a9e 0%, #1e90ff 100%);
}

.blue-compact-btn:disabled {
	background: #6c757d;
	color: #ccc;
	cursor: not-allowed;
}

.header-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 12px; /* Space between rows */
}

.header-label {
	font-size: 16px;
	color: #b0b0b0; /* Muted light grey for labels */
	// font-weight: bold;
	flex: 1; /* Make label occupy equal space */
}

.header-input {
	flex: 3; /* Larger space for inputs */
	position: relative;
}

.header-input .rbt-input-main {
	background: #2e2e40; /* Darker input background */
	color: #ffffff; /* Light text color */
	border: 1px solid #4a4a4a; /* Subtle border */
	border-radius: 4px;
	padding: 8px;
	font-size: 14px;
}

.header-input .rbt-input-main::placeholder {
	color: #aaaaaa; /* Subtle grey for placeholder text */
}

.header-input .rbt-menu {
	background: #2e2e40; /* Dropdown menu background */
	color: #ffffff; /* Menu text color */
	border: 1px solid #4a4a4a; /* Border for dropdown */
	border-radius: 4px;
}

.btn-save-layout {
	background: #007bff; /* Blue gradient */
	color: #ffffff;
	padding: 8px 16px;
	border: none;
	border-radius: 4px;
	font-size: 14px;
	font-weight: bold;
	cursor: pointer;
}

.btn-save-layout:hover {
	background: #0056b3; /* Darker blue on hover */
}

.btn-save-layout:disabled {
	background: #6c757d; /* Grey for disabled */
	cursor: not-allowed;
}
.summary-card-compact {
	background: #2e2e40; /* Dark background */
	border: 1px solid #2e2e40; /* Subtle border */
	border-radius: 4px;
	padding: 6px;
	color: #ffffff; /* Light text */
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); /* Subtle shadow */
	width: 100%; /* Full width */
}

.summary-compact-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(160px, 1fr)); /* Responsive grid */
	// gap: 6px; /* Space between items */
	width: 100%; /* Ensure the grid spans the full width */
}

.summary-compact-item {
	background: #2e2e40; /* Slightly lighter background for items */
	border-radius: 4px;
	padding: 6px;
	width: 100%; /* Ensure each item spans 100% of its column */
	display: flex;
	flex-direction: column; /* Allow stacking rows vertically */
	gap: 6px; /* Space between rows */
}

.summary-row {
	display: flex;
	justify-content: space-between; /* Place label and value on opposite sides */
	align-items: center; /* Vertically align content */
}

.summary-label {
	font-size: 12px;
	color: #aaaaaa; /* Muted grey for labels */
	text-align: left;
}

.summary-value {
	font-size: 12px;
	color: #ffffff; /* Bright white for values */
	text-align: right;
}
//---------

.availability-summary-card-compact {
	background: #2e2e40; /* Dark background */
	border: 1px solid #2e2e40; /* Subtle border */
	border-radius: 4px;
	padding: 6px;
	color: #ffffff; /* Light text */
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); /* Subtle shadow */
	width: 100%; /* Full width */
}

.availability-summary-compact-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(160px, 1fr)); /* Responsive grid */
	// gap: 6px; /* Space between items */
	width: 100%; /* Ensure the grid spans the full width */
}

.availability-summary-compact-item {
	background: #2e2e40; /* Slightly lighter background for items */
	border-radius: 4px;
	padding: 6px;
	width: 100%; /* Ensure each item spans 100% of its column */
	display: flex;
	flex-direction: column; /* Allow stacking rows vertically */
	gap: 6px; /* Space between rows */
}

.availability-summary-row {
	display: flex;
	justify-content: space-between; /* Place label and value on opposite sides */
	align-items: center; /* Vertically align content */
}

.availability-summary-label {
	font-size: 12px;
	color: #aaaaaa; /* Muted grey for labels */
	text-align: left;
}

.availability-summary-value {
	font-size: 12px;
	color: #ffffff; /* Bright white for values */
	text-align: right;
}

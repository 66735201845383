.rc-slider-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 10px 20px 0;
	border-top: 1px solid var(--grey-6);
	padding: 1rem 0 1.5rem;
	.range-text,
	label {
		white-space: nowrap;
		font-size: 0.875rem;
		font-weight: 600;
		color: var(--grey-5);
		margin-bottom: 0;
	}
	.rc-slider {
		//width: 500px;
		height: 20px;
		.rc-slider-rail {
			opacity: 0.5;
			height: 9px;
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fa695d+0,f5de8f+100 */
			background: #fa695d; /* Old browsers */
			background: -moz-linear-gradient(left, #fa695d 0%, #f5de8f 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left, #fa695d 0%, #f5de8f 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(
				to right,
				#fa695d 0%,
				#f5de8f 100%
			); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fa695d', endColorstr='#f5de8f',GradientType=1 ); /* IE6-9 */
		}
		.rc-slider-track {
			height: 9px;
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fa695d+0,f5de8f+100 */
			background: #fa695d; /* Old browsers */
			background: -moz-linear-gradient(left, #fa695d 0%, #f5de8f 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left, #fa695d 0%, #f5de8f 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(
				to right,
				#fa695d 0%,
				#f5de8f 100%
			); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fa695d', endColorstr='#f5de8f',GradientType=1 ); /* IE6-9 */
		}
		.rc-slider-handle {
			background: url(../svg/range-marker.svg) no-repeat;
			width: 19px;
			height: 23px;
			margin: -14px 0 0px 0px;
			border: none;
			&:active,
			&:focus {
				box-shadow: none !important;
			}
		}
		&.vertical-gradient {
			.rc-slider-rail,
			.rc-slider-track {
				/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fa695d+0,f5de8f+100 */
				background: #fa695d; /* Old browsers */
				background: -moz-linear-gradient(bottom, #fa695d 0%, #f5de8f 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(bottom, #fa695d 0%, #f5de8f 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(
					to bottom,
					#fa695d 0%,
					#f5de8f 100%
				); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fa695d', endColorstr='#f5de8f',GradientType=1 ); /* IE6-9 */
			}
		}
	}
	.min,
	.max {
		padding: 0 0.8rem;
	}
	.min {
		padding-left: 0.3rem;
	}
}

.rc-slider {
	&.price-range-slider {
		.rc-slider-rail {
			background: #ff695d;
		}
		.rc-slider-track {
			background: #66bc80;
		}
		.rc-slider-handle {
			height: 1rem;
			width: 1rem;
			background: var(--primary);
			border: 1px solid #c6c6c6;
		}
		.rc-slider-dot {
			width: 4px;
			border-color: #fff;
		}
		.rc-slider-dot-active {
			border-color: var(--rc-slider-dot-border);
			background-color: #c5cbf0;
		}
	}
	&.inefficiencies-slider {
		.rc-slider-rail {
			background: #dbdbdb;
		}
		.rc-slider-track {
			background: var(--rc-circle-handle);
		}
		.rc-slider-handle {
			height: 1rem;
			width: 1rem;
			background: var(--rc-circle-handle);
			border: 1px solid #c6c6c6;
		}
		&.borrow-price {
			.rc-slider-rail {
				background: #66bc80;
			}
			.rc-slider-track {
				background: #ff695d;
			}
		}
		&.sell-price {
			.rc-slider-rail {
				background: #ff695d;
			}
			.rc-slider-track {
				background: #66bc80;
			}
		}
	}
}
.rc-slider-tooltip {
	z-index: 10000000;
}

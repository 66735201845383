a {
	color: var(--link-color);
	&:hover {
		color: var(--link-hover);
	}
	&:active {
		color: var(--link-active);
	}
}

// BUTTON SIZES
.btn {
	white-space: nowrap;
	&:disabled {
		cursor: not-allowed;
	}
}

.btn-primary {
	background-color: var(--btn-primary);
	border-color: var(--btn-primary);
}

.btn-secondary {
	background-color: var(--btn-secondary);
	border-color: var(--btn-secondary);
	color: var(--btn-secondary-color);
}

.btn-outline-secondary {
	color: var(--btn-secondary);
	border-color: var(--btn-secondary);
}

.btn-outline-light {
	border-color: var(--border-color);
}

.btn-xl {
	font-size: 1.5rem;
	padding: 0.75rem 2.0625rem;
}

.btn-xs {
	font-size: 0.8125rem;
	padding: 0.25rem 1.125rem;
}

.btn-group-sm {
	.btn {
		line-height: 22px;
		padding-top: 0;
		padding-bottom: 0;
	}
}

.btn-group-xs {
	.btn {
		font-size: 0.875rem;
		padding: 0.2rem 0.5rem;
	}
}

.btn-tiny {
	font-size: 0.6875rem;
	padding: 0.1875rem 0.75rem;
}

.btn-green {
	background: var(--green-1);
	border-color: var(--green-2);
	color: var(--green-3);
	&:hover {
		color: inherit;
	}
}

// Default Input
.form-control {
	&:disabled {
		background: var(--input-disabled-bg);
	}
}
.form-control[type='email'],
.form-control[type='text'],
.form-control[type='password'],
.form-control[readonly],
.form-control {
	background: transparent;
	border-color: var(--dropdown-border-color);
	&::placeholder {
		color: var(--grey-1);
	}
	&:active,
	&:focus {
		color: var(--dropdown-item-color);
		background: transparent;
	}
	color: var(--dropdown-item-color);
	&:disabled {
		background-color: var(--input-disabled-bg);
	}
}

.rt-thead.-filters {
	input,
	select {
		background: transparent !important;
		border-color: var(--dropdown-border-color) !important;
		&::placeholder {
			color: var(--grey-1) !important;
		}
		&:active,
		&:focus {
			color: var(--dropdown-item-color) !important;
			background: transparent !important;
		}
		color: var(--dropdown-item-color) !important;
	}
}

// LINKS
.link {
	color: var(--link-color) !important;
	background: none;
	border: none;
	outline: none !important;
	cursor: pointer;
	&:hover {
		color: var(--link-hover);
	}
	&:active,
	&:focus {
		color: var(--link-active);
	}
}

// Input with Search Icon
.has-search {
	position: relative;
	.form-control {
		padding-left: 1.7rem;
		height: 36px;
		font-size: 0.875rem;
	}
	.form-control-feedback {
		position: absolute;
		z-index: 2;
		display: block;
		color: #aaa;
		margin-left: 9px;
		top: 1px;
		display: flex;
		align-items: center;
		height: 100%;
	}
	&.has-search-sm {
		.form-control {
			height: 28px;
		}
	}
}

.has-percentage-input {
	position: relative;
	.form-control {
		padding-left: 12px;
		height: 36px;
		font-size: 0.875rem;
		border-radius: 2px;
	}
	.form-control-feedback {
		position: absolute;
		z-index: 2;
		display: block;
		color: #444444;
		right: 9px;
		top: 1px;
		display: flex;
		align-items: center;
		height: 100%;
	}
	&.has-search-sm {
		.form-control {
			height: 24px;
		}
	}
}

.mandatory {
	position: relative;
	&:after {
		content: '*';
		color: $danger;
		padding-left: 0.3rem;
	}
}

.custom-dropdown > div.dropdown-menu.show {
	left: 22px !important;
}
.custom-dropdown.primary > div.dropdown-menu.show {
	left: unset !important;
}

// Dropdowns
.dropdown {
	.dd-label {
		display: block;
		font-size: 0.875rem;
		color: var(--grey-5);
		margin-bottom: 0.22rem;
	}
	svg {
		vertical-align: middle;
	}
	.dropdown-toggle {
		height: 2.25rem;
		border: 1px solid var(--dropdown-border-color);
		color: var(--dropdown-item-color);
		font-size: 0.9375rem;
		text-align: left;
		//padding: 0.5rem;
		padding: 0.5rem 32px 0.5rem 12px;
		width: 100%;
		//display: flex;     // Commented to support ellipsis
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		justify-content: space-between;
		align-items: center;
		position: relative;
		&:after {
			background: url(./svg/dropdown-arrow.svg) no-repeat;
			position: absolute;
			right: 10px;
			bottom: 0;
			top: 0;
			margin: auto;
			height: 6px;
			border: none;
			width: 10px;
		}
		&:disabled {
			background: var(--input-disabled-bg);
		}
		&.btn-dropdown {
			border: 1px solid var(--secondary);
			color: var(--secondary);
			&:after {
				display: none;
			}
			&:disabled {
				background: var(--input-disabled-bg);
			}
		}
		&.no-caret {
			padding: 0;
			box-shadow: none;
			&:after {
				display: none;
			}
		}
	}
	.dropdown-menu {
		width: 100%;
		max-height: 190px;
		overflow-y: auto;
		overflow-x: hidden !important;
		.dropdown-item {
			font-size: 0.875rem;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			&:hover {
				//background: var(--text-2);  // Commented as text color is white on btn click on dropdowns
			}
			&:disabled {
				background: var(--input-disabled-bg);
			}
		}
		a {
			&:active {
				* {
					color: var(--white) !important;
				}
			}
		}
	}
	&.v-dots {
		.dropdown-toggle {
			border: none;
			box-shadow: none;
			&:after {
				display: none;
			}
		}
		.dropdown-menu {
			left: -50px !important;
		}
	}
	&.has-filters {
		position: relative;
		&:after {
			position: absolute;
			top: -4px;
			right: 10px;
			width: 8px;
			height: 8px;
			background: var(--primary);
			border-radius: 50px;
			content: '';
		}
	}
}

.count {
	background: var(--count-bg);
	min-width: 1.2rem;
	min-height: 1rem;
	border-radius: 4px;
	position: absolute;
	line-height: 1rem;
	color: var(--count-color);
	font-size: 0.75rem;
	font-weight: 600;
	top: 5px;
	right: -1px;
	margin: -0.8rem 0rem 0 0;
}

// Button Icon
.btn-icon {
	border-radius: 4px;
	border: 1px solid var(--grey-4);
	padding: 0.313rem 0.5rem;
	display: block;
	color: var(--grey-4);
	min-width: 2.5rem;
	cursor: pointer;
	text-align: center;
	position: relative;
}

// Multi Tabs
.multi-tabs {
	display: flex;
	span {
		border: 1px solid var(--multi-tab-border);
		padding: 0.157rem 0rem;
		border-radius: 4px 0 0px 4px;
		font-size: 0.875rem;
		flex: 1;
		text-align: center;
		cursor: pointer;
		&:last-child {
			border-left: 1px solid transparent;
			border-radius: 0 4px 4px 0;
			margin-left: -1px;
		}
		&.active {
			border-color: var(--multi-tab-active-border);
			background: var(--multi-tab-active-bg);
			color: var(--multi-tab-active-color);
		}
	}
}

// Input Text
.custom-input {
	font-size: 0.875rem;
	color: var(--grey-5);
	label {
		margin-bottom: 0.22rem;
	}
	input {
		padding: 0.5rem;
		height: 2.25rem;
		line-height: normal;
		width: 100%;
		border: 1px solid var(--dropdown-border-color);
		color: var(--dropdown-item-color);
		font-size: 0.875rem;
		background: transparent;
	}
}

.input-sm {
	width: 3.2rem;
	padding: 0.15rem 0.5rem;
	height: auto;
	font-size: 0.875rem;
}

.input-autocomplete {
	position: relative;
	> div {
		width: 100%;
		[role='combobox'] {
			border-radius: 4px;
			& + div {
				border: 1px solid var(--grey-6);
				border-radius: 4px;
				padding: 4px 0;
				position: absolute;
				left: auto !important;
				top: auto !important;
				z-index: 10;
				background: var(--white);
				div {
					padding: 4px 10px;
					&:hover {
						background: var(--dropdown-item-hover-bg);
						cursor: pointer;
					}
				}
			}
		}
	}
}

// Datepicker.
.datepicker-wrapper {
	position: relative;
	label {
		font-size: 0.875rem;
		color: var(--grey-5);
		margin-bottom: 0.22rem;
	}
	.react-datepicker-wrapper {
		width: 100%;
		.react-datepicker__input-container {
			width: 100%;
			input {
				height: 2.25rem;
				width: 100%;
				border: 1px solid var(--dropdown-border-color);
				border-radius: 4px;
				padding: 0.5rem;
				font-size: 0.875rem;
				color: var(--grey-4);
				background: transparent;
			}
		}
	}
	.react-datepicker-popper {
		.react-datepicker {
			.react-datepicker__navigation {
				height: 10px;
				padding: 0 !important;
			}
		}
	}

	.react-datepicker-popper {
		z-index: 11 !important;
	}

	.calendar-icon {
		position: absolute;
		right: 20px;
		bottom: 0.4rem;
		cursor: pointer;
	}
	&.datepicker-left {
		.react-datepicker-popper {
			margin-left: -100px;
			.react-datepicker {
				.react-datepicker__triangle {
					right: 50px;
					left: auto;
				}
			}
		}
	}
	&.form-sm {
		.react-datepicker-wrapper {
			input {
				max-height: 28px;
			}
		}
		.calendar-icon {
			right: 8px;
			bottom: 4px;
		}
	}
	&.timepicker-wrapper {
		.react-datepicker-popper {
			left: auto !important;
			.react-datepicker {
				.react-datepicker__time-container {
					width: auto;
					.react-datepicker__time {
						.react-datepicker__time-box {
							width: auto;
						}
					}
				}
			}
		}
	}
}

// Nav Tabs
.nav-tabs {
	border-bottom-color: var(--nav-tab-border-bottom);
	.nav-item {
		a {
			border: none;
			background: none;
			&.active {
				border-bottom: 2px solid var(--primary);
				background: none;
			}
		}
	}
}

// Checkbox
.custom-checkbox {
	&.no-label {
		padding-left: 0;
		label {
			width: 1.5rem;
			height: 1.5rem;
			&:before,
			&:after {
				left: 0;
			}
		}
	}
}

.form-sm {
	label {
		font-size: 0.8125rem !important;
		margin-bottom: 0.3rem;
		color: var(--grey-5);
	}
	input,
	button {
		padding: 0.25rem 0.5rem !important;
		height: auto;
	}
}

// Accordion
.accordion {
	//background: var(--white);
	.card {
		background: inherit;
		border: none;
		overflow: visible; // Added to show dropdown outside of accordion
		box-shadow: none !important;
		.card-header {
			border-radius: 0;
			background: transparent;
			padding: 0px;
			button {
				text-decoration: none;
				padding: 0;
				font-size: 0.875rem;
				color: var(--grey-5);
				font-weight: 600;
				padding: 0.875rem 0 0.875rem 0rem;
				width: 100%;
				text-align: left;
				line-height: 1;
				&::after {
					content: '';
					background: url(../styles/svg/accordion-arrow-down.svg) no-repeat;
					width: 14px;
					height: 6px;
					display: block;
					position: absolute;
					right: 20px;
					top: 20px;
				}
			}
		}
		.card-body {
			padding: 0;
			.sub-item {
				font-size: 0.875rem;
				//border-top: 1px solid var(--grey-6);
				background: var(--sub-item-bg);
				span {
					cursor: pointer;
					padding: 8px 20px;
					display: block;
					transition: 0.3s all ease;
					border-radius: 4px;
					font-size: 14px;
					&:hover {
						background: var(--text-2);
					}
					&.active {
						color: var(--sub-item-color);
						background: var(--primary);
						font-weight: 600;
					}
				}
				&:last-child {
					border-bottom: none;
					padding-bottom: 28px;
				}
			}
		}
	}
	&.open {
		// background: var(--text-2);
		.card-header {
			button {
				&::after {
					background: url(../styles/svg/accordion-arrow-up.svg) no-repeat;
				}
			}
		}
	}
}

.custom-tabs {
	.nav-item {
		font-size: 0.875rem;
		font-weight: 600;
		border: none;
		padding: 5px 16px;
		color: var(--nav-tab-color);
		&.active {
			color: var(--nav-tab-active-color);
			border-bottom: 2px solid var(--nav-tab-active-border);
			background-color: transparent;
		}
	}
	.tab-content {
		background: var(--card-bg);
	}
	&.non-cash-tabs,
	&.users-list-tabs {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		.tab-content {
			flex-grow: 1;
			> .tab-pane.active {
				height: 100%;
			}
		}
		.summary-tab-table {
			flex: 0 0 25%;

			@media (min-width: 1025px) and (max-width: 1280px) {
				flex: 0 0 35%;
			}
		}
		.tab-pane-inner {
			height: 100%;
			display: flex;
			flex-direction: column;
		}
	}
}

.input-with-unit {
	.unit {
		position: absolute;
		right: 10px;
		top: 0;
		bottom: 0;
		display: flex;
		align-items: center;
	}
}

.dropdown-menu {
	&.rbt-menu {
		li {
			.dropdown-item {
				&:active {
					background: inherit;
				}
			}
		}
	}
}

.dropdown-menu {
	background: var(--dropdown-menu-bg);
	color: var(--dropdown-item-color);
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
	.dropdown-item {
		color: var(--dropdown-item-color);
		border-bottom: 1px solid var(--dropdown-item-bottom-border);
		&:last-child {
			border-bottom: none;
		}
		&:hover,
		&:focus,
		&:active {
			color: var(--dropdown-item-hover-color);
			background: var(--dropdown-item-hover-bg);
		}
	}
}

// Checkbox & Radio Border with events
.custom-control {
	.custom-control-input[type='radio'] {
		&:focus {
			~ .custom-control-label {
				&:before {
					//box-shadow: none !important;
					background: transparent !important;
				}
			}
		}
		&:checked {
			& ~ .custom-control-label {
				&:hover,
				&:active {
					&:before {
						border-color: var(--custom-control-border-active) !important;
					}
				}
				&:before {
					background-color: transparent !important;
				}
				&:after {
					background: var(--custom-control-border-active);
					width: 10px;
					height: 10px;
					border-radius: 50%;
					margin: 3px 0 0 3px;
				}
			}
		}
	}
	.custom-control-input[type='checkbox'] {
		&:focus {
			~ .custom-control-label {
				&:before {
					//box-shadow: none !important;
					background: transparent !important;
				}
			}
		}
		&:checked {
			& ~ .custom-control-label {
				&:hover,
				&:active {
					&:before {
						border-color: var(--custom-control-border-active) !important;
					}
				}
				&:before {
					background-color: var(--custom-control-border-active) !important;
				}
			}
		}
	}
	.custom-control-input[disabled] {
		& ~ .custom-control-label {
			color: var(--custom-control-border-disabled);
			&:hover,
			&:active {
				&:before {
					border-color: var(--custom-control-border-disabled) !important;
					pointer-events: none;
				}
			}
			&:before {
				background-color: transparent !important;
				border-color: var(--custom-control-border-disabled) !important;
				pointer-events: none;
			}
		}
	}
	.custom-control-label {
		color: var(--custom-control-text-color);
		transition: 0.3s all ease;
		&:before {
			border-color: var(--custom-control-border-color);
			background-color: transparent !important;
		}
		&:hover,
		&:focus {
			color: var(--custom-control-hover-color);
			&:before {
				border-color: var(--custom-control-hover-color);
			}
		}
		&:active {
			color: var(--custom-control-hover-color);
			&:before {
				border-color: var(--custom-control-border-active);
			}
		}
	}
}

// Remove Halo effect everywhere
.btn,
input,
.form-control,
.custom-control-input {
	&:hover,
	&:active,
	&:focus {
		//box-shadow: none !important;
		& + label:before {
			//box-shadow: 0 0 3px 0 rgba(99, 117, 213, 1) !important;
		}
		//box-shadow: 0 0 3px 0 rgba(99, 117, 213, 1) !important;
	}
}

//Highlight Outline
.dropdown-toggle,
.datepicker-wrapper .react-datepicker-wrapper .react-datepicker__input-container input {
	&:focus {
		border-color: #c7cef0;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(99, 117, 213, 0.25);
	}
}

.rbt-input-hint {
	input {
		display: none;
	}
}

.custom-switch-text {
	color: var(--dropdown-item-hover-color);
}

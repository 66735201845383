.column-info-box {
	ul {
		li {
			.value {
				border-right: 1px solid var(--border-color);
				color: var(--dropdown-item-hover-color);
			}
			&:nth-child(3n) {
				.value {
					border-right: 0;
				}
				.label-field {
					padding-left: 20px;
				}
			}
			&:nth-child(3n + 2) {
				.label-field {
					padding-left: 20px;
				}
			}
		}
	}
}

.col-info-box-even {
	ul {
		li {
			.label-field {
				line-height: 24px;
			}
			&:nth-child(odd) {
				.value {
					border-right: 1px solid var(--border-color);
					color: var(--dropdown-item-hover-color);
					padding-right: 20px;
				}
			}
			&:nth-child(even) {
				.label-field {
					padding-left: 20px;
				}
			}
		}
	}
}

.authorize-form-control {
	height: 24px;
	max-width: 70px;
	margin-left: auto;
}

// activity log list, with before border
$log-padding-left: 20px;
$log-border-width: 1px;
$log-dot-size: 8px;
.list-log-activity {
	padding-left: $log-padding-left;
	margin-bottom: 0;
	position: relative;
	&::before {
		content: '';
		width: $log-border-width;
		height: 99%;
		border-left: $log-border-width dashed var(--grey-4);
		position: absolute;
		top: 3px;
		bottom: 0;
		left: $log-padding-left - ($log-dot-size * 2); //4px;
	}
	li {
		padding-bottom: 30px;
		position: relative;
		display: flex;
		flex-direction: column;
		color: var(--dropdown-item-color);
		&:before {
			content: '';
			width: $log-dot-size;
			height: $log-dot-size;
			border-radius: 23px;
			background-color: var(--grey-4);
			position: absolute;
			top: 3px;
			left: -20px;
		}
	}
}

.locate-authorizer-modal {
	.modal-content {
		max-height: 548px;
	}
	.modal-body {
		max-height: 500px;
	}
	.footer-wrap {
		box-shadow: 0 -4px 8px #00000066;
	}
	.footer-rejection-wrap {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: var(--modal-bg);

		textarea {
			resize: none;
		}
	}
}

.locate-requestor-detail-modal {
	.footer-wrap {
		box-shadow: 0 -4px 8px #00000066;
	}
}

.locate-new-request-modal {
	.ag-cell-value {
		overflow: visible !important;
	}
	.react-datepicker-popper {
		left: -90px !important;
	}
	.react-datepicker__triangle {
		display: none;
	}
	.react-datepicker__current-month {
		line-height: 1;
	}
	.datepicker-wrapper .calendar-icon {
		bottom: 0 !important;
		z-index: -1;
	}
	.portfolio-upload-wrapper {
		max-height: 120px;
	}
	.footer-wrap {
		box-shadow: 0 -4px 8px #00000066;
	}
}

.customize-locate-close {
	cursor: pointer;
	position: absolute;
	top: 5px;
	right: 8px;
	zoom: 0.8;
}

.locate-authorizer-new-request-modal {
	.portfolio-upload-wrapper {
		min-height: 96px;
	}
}

.redBorder {
	border: 2px solid red !important;
}
.greenBorder {
	border: 2px solid green !important;
}
.orangeBorder {
	border: 2px solid orange !important;
}

.gridcellPadding {
	padding-left: 10px !important;
	padding-right: 10px !important;
}

.noBoxShadow {
	box-shadow: none !important;
}

.extraSettingsDropdown {
	min-width: 296px !important;
	min-height: 396px !important;
}

.locate-extra-setting {
	margin-left: 8px;
	width: 15px;
	cursor: pointer;
	text-align: center;
}

.alp-inventory-popover {
	border: 1px solid;
	min-width: 180px;
	max-width: 300px;
	word-break: break-all;
	padding: 8px !important;
	font-size: 13px;
}

.alp-inventory-popover-wrapper {
	.arrow {
		&:before,
		&:after {
			display: none;
		}
	}
}

// COLORS
:root {
	--body-bg: #f6f6f6;
	--modal-bg: #fff;
	--black: #000;
	--primary: #6375d5;
	--secondary: #00bcaf;
	--border-color: #dbdbdb;
	--react-table-bg: #fff;
	// Gray Shades
	--grey-1: #474e5a;
	--grey-2: #707070;
	--grey-3: #a3a3af;
	--grey-4: #666666;
	--grey-5: #444444;
	--grey-6: #dbdbdb;
	--grey-7: #e6e6e6;
	--grey-8: #888888;
	--grey-9: #3d4664;
	--grey-10: #333333;
	--grey-11: #001d31;
	--grey-12: #222222;
	--grey-13: #b3b3b3;
	--grey-14: #555555;
	--grey-15: #f4f4f4;
	--card-shadow-color: 0 0 0.25rem 0 rgba(0, 0, 0, 0.18);
	--white: #fff;
	--text-1: #e3dcdc;
	--text-2: #f4f5ff;
	--card: #fff;
	--orderId: #4b8bf0;
	--nav-bg: #474e5a;
	--nav-color: #e6e6ff;
	--nav-hover: #e6e6ff;
	--nav-active: #fff;
	--nav-user: #363745;
	--nav-submenu-active: #575862;
	--nav-submenu-active-color: #fff;
	--link-color: #0b61eb;
	--link-hover: #142eb9;
	--link-active: #7f3fb5;
	--badge-rajah: #f5be65;
	--badge-flamingo: #f46146;
	--badge-yellow: #ffe798;
	--badge-belize: #2980b9;
	--badge-nephritis: #27ae60;
	--badge-orange: #f48351;
	--badge-purple: #a26dea;
	--badge-green: #5cc9a7;
	--badge-blue: #648ce5;
	--badge-pastel: #d6b500;
	--badge-dark: #6c757d;
	--badge-brown: #b95f4e;
	--badge-maitai: #af6807;
	--info-yellow: #feffe2;
	--yellow-color: #f39c11;
	--checkmark-green: #15c968;
	--green-1: #ebfff4;
	--green-2: #c4e6cf;
	--green-3: #2da050;
	--green-4: #e4f7e7;
	--green-5: #c2e3cb;
	--orange-1: #ffeac9;
	--orange-2: #f2e6d3;
	--orange-3: #dd8f25;
	--danger-1: #ffd3bc;
	--danger-2: #ffaca5;
	--danger-3: #e84033;
	--table-header-bg: #f5f7fa;
	--inventory-text: var(--grey-5);
	--input-disabled-bg: #f4f4f4;
	--dropdown-item-color: #212529;
	--dropdown-menu-bg: #fff;
	--dropdown-border-color: #a3a3af;
	--dropdown-item-hover-color: #212529;
	--dropdown-item-hover-bg: #f8f9fa;
	--dropdown-item-bottom-border: #fff;
	--multi-tab-border: var(--grey-3);
	--multi-tab-active-border: var(--primary);
	--multi-tab-active-color: #fff;
	--multi-tab-active-bg: var(--primary);
	--nav-tab-border-bottom: #dee2e6;
	--nav-tab-color: var(--grey-5);
	--nav-tab-active-border: var(--primary);
	--nav-tab-active-color: var(--primary);
	--custom-control-checked: var(--primary);
	--no-data-found: #ececec;
	// Buttons Variables
	--btn-primary: #6375d5;
	--btn-secondary: #00bcaf;
	--btn-secondary-color: #ffffff;
	--card-border-color: var(--border-color);
	--card-active-color: #fff;
	// Custom Control Variables
	--custom-control-border-color: #b3b3b3;
	--custom-control-border-hover: #666666;
	--custom-control-border-active: #5b66a3;
	--custom-control-border-disabled: #dbdbdb;
	--custom-control-text-color: #b3b3b3;
	// Portfoilio Status BG
	--activity-icon-color: #fff;
	--activity-success-border: var(--green-5);
	--activity-success-background: var(--green-4);
	--activity-success-icon-background: var(--checkmark-green);
	--activity-success-icon-color: #fff;
	--activity-warning-icon-color: #fff;
	--activity-error-icon-color: #fff;
	--activity-success-data-color: var(--green-3);
	--activity-warning-border: var(--orange-2);
	--activity-warning-background: var(--orange-1);
	--activity-warning-icon-background: var(--warning);
	--activity-warning-data-color: var(--orange-3);
	--activity-error-border: var(--danger-2);
	--activity-error-background: var(--danger-1);
	--activity-error-icon-background: var(--danger);
	--activity-error-data-color: var(--danger-3);
	--react-table-bg-even: #f8fcff;
	--rc-slider-green: #66bc80;
	--rc-slider-red: #ff695d;
	--rc-slider-dot-border: #96dbfa;
	--rc-circle-handle: var(--primary);
	--pvtAttrDefault: #fff;
	--pvtAttrBlue: #e5f6ff;
	--pvtAttrYellow: #ffffe2;
	--pivot-table-border-color: #a2b1c6;
	--view-today-active-color: #676c8b;
	--view-today-active-border: #676c8b;
	--detailed-calendar-border: #fff;
	--calendar-border: var(--grey-4);
	--calendar-no-data-bg: #fff;
	--calendar-no-data-coolor: #444;
	--active-calendar-no-data-bg: #fff;
	--active-calendar-no-data-color: #444;
	--scroll-color: #aaa;
	--security-initial-active-color: #fff;
	--security-initial-bg: var(--primary);
	--count-bg: var(--danger);
	--count-color: var(--white);
	--rt-border: #dbdbdb;
	--sub-item-bg: #fff;
	--sub-item-color: #fff;
	--bg-blue: #e5f6ff;
	--bg-yellow: #ffffe2;
	--bg-cream: #ffeadb;
	--error-bg: rgba(255, 0, 0, 0.1);
	--ajax-loader-bg: rgba(99, 117, 213, 0.5);
	--ajax-animator-color: var(--primary);
	--component-loader-overlay: rgba(0, 0, 0, 0.1);
	--dashboard-card-active-bg: var(--primary);
	--decision-scoring-grpah-bg: #fff;
	// CB Icons
	--negative-average: #f0b658;
	--negative-deficient: #ff695d;
	--negative-substandard: #ff695d;
	--positive-average: #f0b658;
	--positive-decent: #66bc80;
	--positive-superior: #66bc80;
	//react table loader color
	--react-table-loading-bg: rgba(255, 255, 255, 0.8);
	//pivot table colors
	--pivot-svg-bg-color: #ffffff;
	--pivot-table-dropdown-bg: #ffffff;
	--pivot-table-dropdown-value: #2a3f5f;
	//menu config colors
	--menu-config-green: #fff;
	--menu-config-green-1: #378a84;
	--menu-config-purple: #848bb3;
	--menu-config-bg-purple: #6375d5;
	--menu-item-title-color: #a8a8a8;
	--menu-item-active-title-color: #ffffff;
	--menu-item-border-color: #dbdbdb;
	--menu-config-modal-close-color: var(--menu-item-border-color);
	--selected-menu-item-color: #333333;
	--selected-menu-subheader-color: #333333;
	--selected-menu-item-lable-color: #444444;
	--available-menu-item-icon-color: #a3a3af;
	--selected-menu-item-icon-color: #666666;
	--more-action-dropdwon-color: #676c8b;
	--navigation-active-checkmark-color: #fff;
	--navigation-burger-bg-color: #7a8cac;
	--nav-sub-item-color: #a3a3af;
	//feature menu Page colors
	--feature-modal-bg: #f6f6f6;
	--feature-modal-header-color: #666666;
	--add-ribbon-bg: #32d395;
	--add-ribbon-color: #063e0d;
	--beta-ribbon-bg: #86baf7;
	--beta-ribbon-color: #214979;
	--soon-ribbon-bg: #d3a832;
	--soon-ribbon-color: #3e1d06;
	--feature-card-bg: #1c1c1c;
	--feature-card-bg: #ffffff;
	--feature-number-tag-bg: #6375d5;
	--feature-number-tag-color: #ffffff;
	--feature-list-item-border-color: #dbdbdb;
	--feature-list-item-color: #333333;
	--feature-list-item-svg-color: #666666;
	--feature-text-orange: #e6940f;
	--feature-text-green: #16b968;
	--feature-text-torquoise: #7165d5;
	--feature-text-peach: #e57f51;
	--feature-text-blue: #2493c4;
	--feature-text-purple: #ad3dc7;
	--feature-text-lightgreen: #39ac88;
	--feature-text-pink: #ea646a;
	--feature-text-cyan: #6eb428;
	--step-progressbar-active: #c5cbf0;
	--step-progress-pointer: #fff;
	--step-pointer-color: #fff;
	--negotiation-card-bg: #ffffff;
	--negotiation-received: #a27139;
	--negotiation-sent: #4f879b;
	--coral: #b95f4e;
	--leaf-green: #248658;
	--tag-purple: #a5b2d5;
	--tag-green: #adc7a4;
	--tag-grey: #e7e7e7;
	--accordion-border-color: transparent;
	--accordion-bg-color: #ffffff;
	--accordion-open-border-color: #666666;
	--accordion-open-bg-color: #444444;
	--left-nav-active-color: #ffffff;
	--left-nav-active-bg: #6375d5;
	--common-header-bg: #ffffff;
	--header-logo-bg: #ffffff;
	--header-content-color: #444444;
	--nav-item-icon-color: #e6e6ff;
	--navigation-burger-bg-color: var(--nav-item-icon-color);
	--nav-profile-bg: var(--nav-item-icon-color);
	--profile-text-color: #e6e6ff;
	--header-logo-color: #092e5d;
	--login-logo-color: #000000;
	--login-label-color: #444444;
	--login-input-border-color: #a3a3af;
	--login-input-default: #b3b3b3;
	--login-input-active: #222222;
	--login-input-filled: #333333;
	--login-header-color: #444444;
	--login-header-border: #b3b3b3;
	--login-outer-bg: #f6f6f6;
	--right-border-color: #dbdbdb;
	--image-description-color: #444444;
	--carousal-indicator-bg: #b3b3b3;
	--carousal-indicator-active-bg: #5b66a3;
	--carousal-indicator-active-border: #c5cbf0;
	--popover-text-color: #444444;
	--text-color-666: #666666;
	--text-color-444: #444444;
	--gray-light: #999999;
	--section-header-bg: #edeeff;
	--tree-view-bg-color: #ffffff;
	--tree-icon-bg: #dbdbdb;
	--tree-icon-border: #a3a3af;
}

html[data-theme='dark'] {
	--primary: #5b66a3;
	--secondary: #2a6e68;
	--body-bg: #141414;
	--modal-bg: #1c1c1c;
	--white: #141414;
	--border-color: #444;
	--react-table-bg: #1c1c1c;
	--black: #fff;
	--grey-4: #a8a8a8;
	--grey-5: #a8a8aa;
	--grey-6: var(--border-color);
	--grey-7: #272727;
	--grey-9: #bcbcbc;
	--grey-10: #a8a8a8;
	--grey-12: #c8c8c8;
	--grey-15: #131313;
	--card-shadow-color: 0 0 6px 0 rgba(0, 0, 0, 0.3);
	--link-color: #6592db;
	--link-hover: #4675c2;
	--link-active: #9274c2;
	--green-1: #202e21;
	--green-2: #6aab77;
	--green-3: #6aab77;
	--nav-bg: #212121;
	--nav-user: #171717;
	--nav-submenu-active: #333;
	--card: #1c1c1c;
	--orderId: #4b8bf0;
	--text-2: #2c2c2c;
	--info-yellow: #666;
	--dropdown-menu-bg: #222;
	--dropdown-item-color: #a8a8a8;
	--dropdown-border-color: #444;
	--dropdown-item-hover-color: #e2e2e2;
	--dropdown-item-hover-bg: #313238;
	--dropdown-item-bottom-border: #333;
	--multi-tab-border: #444444;
	--multi-tab-active-border: #5b66a3;
	--multi-tab-active-color: #c5cbf0;
	--multi-tab-active-bg: #5b66a3;
	--table-header-bg: #242424;
	--inventory-text: var(--grey-7);
	--nav-tab-border-bottom: #333;
	--nav-tab-color: #999999;
	--nav-tab-active-border: #5b66a3;
	--nav-tab-active-color: #5b66a3;
	--input-disabled-bg: #292929;
	--custom-control-checked: #e2e2e2;
	--no-data-found: #292929;
	// Button Variables
	--btn-primary: #5b66a3;
	--btn-secondary: #298a83;
	--btn-secondary-color: #a1e6e1;
	--card-border-color: #6a7086;
	--card-active-color: #c5cbf0;
	// Custom Control Variables
	--custom-control-border-color: #666;
	--custom-control-border-hover: #e2e2e2;
	--custom-control-border-active: #5b66a3;
	--custom-control-border-disabled: #444444;
	--custom-control-text-color: #a8a8a8;
	// Portfoilio Status BG
	--activity-success-border: #6aab77;
	--activity-success-background: transparent;
	--activity-success-icon-background: #6aab77;
	--activity-success-data-color: #6aab77;
	--activity-success-icon-color: #253d2a;
	--activity-warning-border: var(--orange-2);
	--activity-warning-background: var(--orange-1);
	--activity-warning-icon-background: var(--warning);
	--activity-warning-data-color: var(--orange-3);
	--activity-warning-icon-color: #3d321e;
	--activity-error-border: var(--danger-2);
	--activity-error-background: var(--danger-1);
	--activity-error-icon-background: var(--danger);
	--activity-error-data-color: var(--danger-3);
	--activity-error-icon-color: #4d231f;
	--react-table-bg-even: #242424;
	--rc-slider-green: #66bc80;
	--rc-slider-red: #ff695d;
	--rc-slider-dot-border: #96dbfa;
	--rc-circle-handle: #5b66a3;
	--pvtAttrDefaultColor: #a8a8a8;
	--pvtAttrDefault: #2e2d2d;
	--pvtAttrDefaultBorder: #666;
	--pvtAttrBlue: #1d2b31;
	--pvtAttrYellow: #35321b;
	--pivot-table-border-color: #333;
	--view-today-active-color: #8d91a8;
	--view-today-active-border: #76798c;
	--view-today-disabled: #444;
	--detailed-calendar-border: rgba(0, 0, 0, 0.3);
	--calendar-border: #333;
	--calendar-no-data-bg: #242424;
	--calendar-no-data-coolor: #a8a8a8;
	--active-calendar-no-data-bg: #444;
	--active-calendar-no-data-color: #a8a8a8;
	--scroll-color: #444;
	--security-initial-active-color: #a8a8a8;
	--security-initial-bg: #5b66a3;
	--count-bg: #c9706a;
	--count-color: #501f19;
	--rt-border: #333;
	--sub-item-bg: #212121;
	--sub-item-color: #a9b5d8;
	--bg-blue: #141a1c;
	--bg-yellow: #242424;
	--bg-cream: #1c1a11;
	--ajax-loader-bg: rgba(99, 117, 213, 1);
	--ajax-animator-color: #16bcaf;
	--component-loader-overlay: rgba(0, 0, 0, 0.6);
	--dashboard-card-active-bg: #33365a;
	--decision-scoring-grpah-bg: #f6f6f6;
	// CB Icons
	--negative-average: #b3895d;
	--negative-deficient: #cc5c52;
	--negative-substandard: #cc5c52;
	--positive-average: #b3895d;
	--positive-decent: #6aab77;
	--positive-superior: #6aab77;
	//react table loader color
	--react-table-loading-bg: rgba(0, 0, 0, 0.4);
	// Dark Theme Classes
	body {
		color: var(--grey-5);
	}
	.text-black {
		color: var(--grey-5);
	}
	.badge-primary {
		background: var(--primary);
	}
	.totalTxt p {
		color: var(--grey-12);
	}
	.inv-optimization-table {
		.bg-blue,
		.bg-yellow,
		.bg-cream {
			color: var(--grey-5) !important;
		}
	}
	.ReactTable .-loading {
		background: var(--react-table-loading-bg);
		> div {
			color: var(--text-1);
		}
	}
	//pivot table colors
	--pivot-svg-bg-color: inherit;
	--pivot-table-dropdown-bg: #1d1d1d;
	--pivot-table-dropdown-value: #e3dcdc;
	//menu config colors
	--menu-config-green: #378a84;
	--menu-config-green-1: #378a84;
	--menu-config-purple: #848bb3;
	--menu-config-bg-purple: #333c5a;
	--menu-item-title-color: #a8a8a8;
	--menu-item-active-title-color: #c5cbf0;
	--menu-item-border-color: #333333;
	--menu-config-modal-close-color: #707070;
	--selected-menu-subheader-color: #7e7e95;
	--selected-menu-item-color: #c4c4c4;
	--selected-menu-item-lable-color: #7e7e96;
	--available-menu-item-icon-color: #7e7e96;
	--selected-menu-item-icon-color: #c4c4c4;
	--navigation-active-checkmark-color: #6aab77;
	--nav-sub-item-color: #7e7e96;
	//feature menu Page colors
	--feature-modal-bg: #141414;
	--feature-modal-header-color: #a8a8a8;
	--add-ribbon-bg: #32d395;
	--add-ribbon-color: #063e0d;
	--beta-ribbon-bg: #86baf7;
	--beta-ribbon-color: #214979;
	--soon-ribbon-bg: #d3a832;
	--soon-ribbon-color: #3e1d06;
	--feature-card-bg: #1c1c1c;
	--feature-number-tag-bg: #333333;
	--feature-number-tag-color: #e2e2e2;
	--feature-list-item-border-color: #333333;
	--feature-list-item-color: #d4d4ff;
	--feature-list-item-svg-color: #7e7e96;
	--feature-text-orange: #d5aa65;
	--feature-text-green: #00a867;
	--feature-text-torquoise: #8f88c7;
	--feature-text-peach: #e3a386;
	--feature-text-blue: #4eabbc;
	--feature-text-purple: #a871bc;
	--feature-text-lightgreen: #9bc671;
	--feature-text-pink: #db7070;
	--feature-text-cyan: #ced6a1;
	// --step-progressbar-active: #212529;
	--step-pointer-color: #222222;
	--step-progressbar-active: #c5cbf0;
	--negotiation-card-bg: #2b2b2b;
	--negotiation-received: #a27139;
	--negotiation-sent: #4f879b;
	--coral: #b95f4e;
	--leaf-green: #248658;
	--tag-purple: #a5b2d5;
	--tag-green: #adc7a4;
	--accordion-border-color: #333333;
	--accordion-bg-color: #242424;
	--accordion-open-border-color: #666666;
	--accordion-open-bg-color: #444444;
	--left-nav-active-color: #c5cbf0;
	--left-nav-active-bg: #333c5a;
	--common-header-bg: #071d39;
	--header-logo-bg: #092e5d;
	--header-content-color: #d6dde5;
	--nav-item-icon-color: #7e7e96;
	--navigation-burger-bg-color: #7a8cac;
	--profile-text-color: #a8a8a8;
	--login-logo-color: #ffffff;
	--login-label-color: #c4c4c4;
	--login-input-border-color: #3c4e6a;
	--login-input-default: #5c6776;
	--login-input-active: #c4c4c4;
	--login-input-filled: #c4c4c4;
	--header-logo-color: #ffffff;
	--login-header-color: #c4c4c4;
	--login-header-border: #3c4e6a;
	--login-outer-bg: #141414;
	--right-border-color: transparent;
	--image-description-color: #ffffff;
	--carousal-indicator-bg: #49607c;
	--carousal-indicator-active-bg: #5b66a3;
	--carousal-indicator-active-border: #c5cbf0;
	--popover-text-color: #a8a8a8;
	--text-color-666: #a8a8a8;
	--text-color-444: #a8a8a8;
	--gray-light: #a8a8a8;
	--section-header-bg: #5b66a3;
	--tree-view-bg-color: #1c1c1c;
	--tree-icon-bg: #1c1c1c;
	--tree-icon-border: var(--border-color);
}

.trading-queue {
	.test:after {
		content: '\2807';
		font-size: 0.9375rem;
	}

	/* Popup container - can be anything you want */
	.popup {
		float: right;
		position: relative;
		display: inline-block;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	/* The actual popup */
	.popup .popuptext {
		width: max-content;
		background-color: #fff;
		color: #000000;
		border-radius: 6px;
		padding: 8px 20px 8px 0;
		position: absolute;
		z-index: 1000;
		top: 22px;
		right: 0;
	}
	.openwidget li:hover {
		background-color: #6375d5;
	}
	#myPopup > span {
		margin-left: 18px;
	}
	.m-t-0 {
		margin-top: 0px;
	}
	.p-10 {
		padding: 10px;
	}
	.m-l-20 {
		margin-left: 20px;
	}

	/* Toggle this class - hide and show the popup */
	.popup .show {
		visibility: visible;
		-webkit-animation: fadeIn 1s;
		animation: fadeIn 1s;
	}

	/* Add animation (fade in the popup) */
	@-webkit-keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
}

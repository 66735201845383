.flyout-menu {
	//width: 30rem;
	height: 100%;
	background: var(--white);
	position: fixed;
	right: -55rem;
	top: 0;
	transition: 0.5s right;
	z-index: 1001;
	display: flex;
	> div {
		//padding: 1rem 1rem 0 1rem;
		overflow: auto;
	}
	svg {
		cursor: pointer;
	}
	&.show {
		right: 0rem;
		~ .overlay {
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.65);
			z-index: 1000;
		}
	}
	.header {
		//margin-bottom: 12px;
		//padding-bottom: 12px;
		h5 {
			font-size: 18px;
			font-weight: 600;
			color: var(--grey-5);
		}
		.close-flyout {
			position: absolute;
			left: -30px;
			top: 15px;
			height: 30px;
			width: 30px;
			background: rgba(0, 0, 0, 0.4);
			display: flex;
			justify-content: center;
			align-items: center;
			color: var(--grey-6);
		}
	}
	.radio-wrapper {
		display: flex;
		justify-content: space-between;
		margin: 0 -1rem 1.31rem -1rem;
		border-bottom: 1px solid var(--grey-2);
		padding: 1.625rem 1rem 1.31rem 1rem;
	}
	.filter-btn-wrapper {
		position: sticky;
		bottom: 0;
		background: var(--white);
		border-top: 1px solid var(--grey-2);
		text-align: right;
		margin: 0 -1rem;
		padding: 1rem;
		display: flex;
		justify-content: flex-end;
	}
	table {
		thead {
			th {
				background: $table-header-bg;
			}
		}
		&.table-borderless {
			tr:last-child {
				td {
					border-bottom: none !important;
				}
			}
		}
	}
	.defaultABCM {
		max-width: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
	}
	.btn-wrapper {
		position: absolute;
		margin: 20px 0 0 -95px;
		transform: rotate(270deg);
		left: 0;
		top: 55px;
		.show-text {
			display: inline-block;
		}
		.hide-text {
			display: none;
		}
		.close-icon {
			background: #3f424c;
			color: #dbdbdb;
			padding: 2px 9px 8px 8px;
			display: inline-block;
			border-radius: 4px 4px 0px 0px;
			cursor: pointer;
		}
		.label {
			background: #3f424c;
			padding: 6px 9px 10px 8px;
			border-radius: 4px 4px 0 0;
			color: #dbdbdb;
			margin: 0px 10px 0px 0px;
			font-size: 0.875rem;
			cursor: pointer;
			svg {
				transform: rotate(270deg);
				margin: 0px 10px 3px 0px;
			}
		}
	}
	&.show-default-abcm {
		.defaultABCM {
			width: 29rem;
			max-width: 100%;
			//padding: 1rem 1rem 0 1rem;
		}
		.btn-wrapper {
			.hide-text {
				display: inline-block;
			}
			.show-text {
				display: none;
			}
		}
		.label {
			svg {
				transform: rotate(90deg);
			}
		}
	}
}

.customABCM {
	display: flex;
	flex-direction: column;
	box-shadow: 0 0 7px 0px rgba(0, 0, 0, 0.2);
	.dragable-container .dragable-item {
		min-width: 239px;
	}
}

.defaultABCM {
	.table-bordered > tbody > tr > td:last-child {
		min-width: 175px;
	}
}

.defaultABCM {
	.table-bordered > tbody > tr > td:last-child {
		min-width: 175px;
	}
}

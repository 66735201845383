.alp-basket-file-upload {
	.portfolio-upload-wrapper {
		min-height: 85px;
	}
}

.alp-new-request-sidebar-logo {
	position: relative;
	top: -3px;
}

.alp-new-request-modal {
	.modal-content {
		height: 750px;
		position: absolute !important;
		width: 100% !important;
		@media only screen and (min-width: 1000px) and (max-width: 1200px) {
			width: 130% !important;
			left: -15% !important;
		}
		@media only screen and (min-width: 1200px) and (max-width: 1400px) {
			width: 100% !important;
			left: 0% !important;
		}
		@media only screen and (min-width: 1401px) and (max-width: 1899px) {
			width: 120% !important;
			left: -10% !important;
		}
		@media only screen and (min-width: 1900px) {
			width: 140% !important;
			left: -20% !important;
		}
	}
}

.alp-negotiation-modal {
	.modal-content {
		height: 700px;
		position: absolute !important;
		width: 140% !important;
		left: -19% !important;
	}

	.yellow-color {
		color: var(--yellow-color);
	}

	.activity-grid-header {
		position: relative;
		bottom: -35px;
	}
	.negotiation-card {
		background: var(--negotiation-card-bg);
	}
	.negotiation-received {
		background: var(--negotiation-received);
	}
	.negotiation-sent {
		background: var(--negotiation-sent);
	}
	.negotiation-tabs {
		.nav-item {
			padding: 10px 16px !important;
		}
		.card-header-tabs {
			margin: 0 !important;
		}
	}
	.alp-preview-box {
		.alp-preview-body {
			ul {
				li {
					.label-field {
						padding-left: 15px;
						font-size: 12px;
					}
					.value {
						font-size: 14px;
						color: var(--custom-control-border-hover);
					}
					&:nth-child(4n) {
						.value {
							border-right: 0 !important;
						}
					}
					&:nth-child(n) {
						.value {
							border-right: 1px solid var(--border-color);
						}
					}
				}
			}
		}
	}

	.offer-rate-badge {
		display: inline-block;
		padding: 0.25em 0.4em;
		font-size: 0.875rem;
		font-weight: 500;
		line-height: 1;
		text-align: center;
		white-space: nowrap;
		vertical-align: baseline;

		padding-right: 0.6em;
		padding-left: 0.6em;
		padding-bottom: 0.5rem !important;
		padding-top: 0.5rem !important;
		border-radius: 10rem;
	}
}

.alp-negotiation-modal,
.alp-new-request-modal-grid,
.alp-basket-grid,
.alp-single-security-grid,
.alp-requester-main-grid,
.alp-browse-availability-main-grid,
.alp-authorizer-main-grid,
.live-auction-main-grid,
.my-bids-main-grid,
.staged-auction-main-grid,
.closed-auction-main-grid,
.brodcast-errors-auction-main-grid,
.auction-bids-grid,
.auction-inventory-grid {
	::-webkit-scrollbar {
		width: 8px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: rgba(112, 112, 112, 0.8);
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	}
	::-webkit-scrollbar-thumb:window-inactive {
		background: rgba(112, 112, 112, 0.8);
	}

	.ag-body-horizontal-scroll,
	.ag-body-horizontal-scroll-viewport,
	.ag-body-horizontal-scroll-container {
		max-height: 8px !important;
		min-height: 8px !important;
		height: 8px !important;
	}

	.ag-center-cols-viewport {
		overflow-x: hidden !important;
	}
}

.alp-basket-grid {
	.ag-cell:focus,
	.ag-cell-range-selected {
		background: none !important;
	}

	.ag-cell-last-left-pinned {
		border-right: solid 1px !important;
		border-right-color: #68686e !important;
		border-right-color: var(--ag-border-color, #68686e) !important;
	}

	.ag-cell-first-right-pinned {
		border-left: solid 1px !important;
		border-left-color: #68686e !important;
		border-left-color: var(--ag-border-color, #68686e) !important;
	}
}

.lowDividendRadio {
	position: relative;
	right: -7px;
}

.noInventoryRow,
.updatedRow {
	background-color: #2c2c57 !important;
	// color: black;
}

.tooltipMsg {
	word-break: break-word;
}

.auction-inventory-grid {
	height: 315px !important;
}
.auction-bids-grid {
	height: 315px !important;
}

.auction-create-edit-modal {
	.modal-content {
		height: 800px;
		position: absolute !important;
		width: 100% !important;
		@media only screen and (min-width: 1000px) and (max-width: 1200px) {
			width: 130% !important;
			left: -15% !important;
		}
		@media only screen and (min-width: 1200px) and (max-width: 1400px) {
			width: 100% !important;
			left: 0% !important;
		}
		@media only screen and (min-width: 1401px) and (max-width: 1899px) {
			width: 120% !important;
			left: -10% !important;
		}
		@media only screen and (min-width: 1900px) {
			width: 140% !important;
			left: -20% !important;
		}
	}
	.modal-body {
		padding: 1rem !important;
	}
}

.auction-bid-entry-modal {
	.auction-borrower-bid-entry {
		.heavy-border-left {
			border-left: 5px solid;
		}
	}
	.modal-body {
		overflow-y: inherit !important;
		padding: 1rem !important;
	}
	.modal-header {
		div:nth-child(1) {
			display: flex;
			align-items: center;
		}
	}
	.badge-success {
		background: #337f0a;
		color: black !important;
		font-weight: bold;
		&.CLOSED {
			background: #939592 !important;
		}
	}

	.bid-entry-header-text {
		color: #337f0a !important;
	}
}

.auction-icon-btn {
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.timerCountdownClass {
	font-size: 16px;
	font-weight: bold;
}

.custom-confirmation-auctions-modal {
	width: 320px;
	.modal-content {
		border: 1px solid #298a83;
	}
}
.banner-box {
	padding: 0px 10px;
	color: black;
	font-weight: 700;
	border-radius: 8px;
	background: deepskyblue;
	&.IN_PROGRESS {
		background: #337f0a;
	}
	&.PENDING_RELEASE {
		background: yellow;
	}
	&.pending_brodcast {
		background: blue;
	}
}

.live-auction-main-grid {
	h6 {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
	}
	.release-count {
		padding: 2px 10px;
		background: yellow;
		color: black;
		font-weight: 700;
		border-radius: 5px;
		margin-left: 10px;
	}
}

.winningOutline {
	border: 2px solid #457e12 !important;
	color: #457e12 !important;
}

.lossingOutline {
	border: 2px solid #b74238 !important;
	color: #b74238 !important;
}

.ineligibleOutline {
	background: #68686e !important;
}

.filled-qty {
	color: #457e12 !important;
	font-weight: bold;
}

.dashboard-cards {
	margin-top: 1rem;
	margin-bottom: 1rem;
	flex-shrink: 0;
	.col {
		display: flex;
		flex: 1;
		.card {
			cursor: pointer;
			padding: 0.5rem;
			width: 100%;
			color: var(--grey-9);
			background: var(--card);
			&.active {
				background: var(--dashboard-card-active-bg);
				color: var(--card-active-color);
				.media {
					&.border-bottom {
						border-color: var(--card-border-color) !important;
					}
				}
			}
			span {
				line-height: normal;
				white-space: nowrap;
			}
			&:hover {
				//transform: scale(1.03);
				transition: 0.25s all linear;
				box-shadow: 0px 2px 6px 3px rgba(0, 0, 0, 0.18) !important;
			}
		}
	}
}

.card-currency {
	position: absolute;
	right: 6px;
	top: 4px;
	font-size: 0.6875rem;
}

.dashboard-grouping-wrapper {
	padding: 0 !important;
	margin: 0 !important;
	border: none;
	margin-top: 0.25rem !important;
	width: 410px !important;
	left: auto !important;
	right: -45px !important;
	max-height: 265px !important;
	.dashboard-grouping {
		display: flex !important;
		background: var(--dropdown-menu-bg);
		z-index: 10;
		border-radius: 4px;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.18);
		width: 100%;
	}
}
.coll-Opt-widget {
	min-height: 465px !important;
	// max-width: 452px !important;
}
.coll-Opt-widget-grid {
	min-height: 165px !important;
	margin: 2px solid green !important;
}

.ag-grid-fs-13px {
	font-size: 13px !important;
}

.ag-grid-fs-17px {
	font-size: 17px !important;
}

.coll-Opt-widget-below {
	min-height: 465px !important;
}
.min-h-colopt-300 {
	min-height: 400px !important;
}
.min-h-colopt-500 {
	min-height: 534px !important;
}
#bubbleChartDiv {
	width: 100%;
	height: 500px;
}

#verticalColumnChartDiv {
	width: 100%;
	height: 500px;
}
.verticalColumnChartDiv {
	width: 100%;
	height: 400px !important;
}

.valmaindivNew {
	padding: 10px;
}
.valmaindiv {
	padding: 0px;
}

.perdiv {
	display: -webkit-box;
}
.perval {
	font-weight: 600 !important;
	font-size: 1.25rem !important;
	color: var(--grey-5) !important;
}

.avgrate {
	font-weight: 300 !important;
	font-size: 14px !important;
	padding: 10px;
	line-height: normal;
}
.subHeading1 {
	font-weight: 300;
	font-size: 14px;
}
.subHeadingGreen {
	font-weight: 300;
	font-size: 14px;
	color: #00ae79;
	padding-left: 10px;
}
.subHeadingRed {
	font-weight: 300;
	font-size: 14px;
	color: #ff8585;
	padding-left: 10px;
}
.subHeadingGreenNew {
	font-weight: 300;
	font-size: 14px;
	color: #00ae79;
	padding-left: 20px;
}
.subHeadingRedNew {
	font-weight: 300;
	font-size: 14px;
	color: #ff8585;
	padding-left: 20px;
}
.valmaindiv i {
	font-size: 26px;
	text-align: left;
}

.valmaindiv .subHeadingGreen i {
	color: #00ae79;
	float: left;
}
.valmaindiv .subHeadingRed i {
	color: #c00000;
	float: left;
}
.coll-opt-dark {
	color: #a8a8aa;
}
.coll-opt-light {
	color: #333333;
}
.viewLink {
	padding-left: 10px;
}

.drag-drop-conatiner {
	display: flex;
}
.draggable-container {
	border: 1px solid lightgrey;
	border-radius: 2px;
	padding: 8px;
	margin-bottom: 8px;
	transition: background-color 0.2s ease;
}
.droppable-container {
	margin: 8px;
	border: 1px solid lightgrey;
	border-radius: 2px;
	width: 220px;
	display: flex;
	flex-direction: column;
}
.droppable-title {
	padding: 8px;
}
.droppable-taskList {
	padding: 8px;
	transition: background-color 0.2s ease;
	flex-grow: 1;
	min-height: 100px;
}

.coll-Opt-widget ::-webkit-scrollbar {
	width: 6px;
}

/* Track */
.coll-Opt-widget ::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle */
.coll-Opt-widget ::-webkit-scrollbar-thumb {
	background: rgba(112, 112, 112, 0.8);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.coll-Opt-widget ::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(112, 112, 112, 0.8);
}

.coll-Opt-widget .ag-body-horizontal-scroll,
.coll-Opt-widget .ag-body-horizontal-scroll-viewport,
.coll-Opt-widget .ag-body-horizontal-scroll-container {
	max-height: 6px !important;
	min-height: 6px !important;
	height: 6px !important;
}

.coll-Opt-widget .ag-center-cols-viewport {
	overflow-x: hidden !important;
}
.coll-Opt-widget .ag-theme-alpine-dark .ag-cell {
	line-height: 22px !important;
}
.coll-Opt-widget .ag-theme-alpine-dark .ag-row {
	height: 22px !important;
}
.coll-Opt-widget .ag-theme-alpine-dark .ag-header-row {
	height: 28px !important;
	font-weight: normal !important;
}
.coll-Opt-widget .ag-theme-alpine-dark .ag-header {
	height: 33px !important;
	min-height: 33px !important;
	padding-left: 0px !important;
	padding-right: 0px !important;
}
.coll-Opt-chart-heading {
	position: relative;
	top: 7px;
}
.dummyWidgetContainer {
	background: #232833;
	.card {
		background: #232833;
		color: white;
		border: 0;
		box-shadow: none;
	}
}

.mt-n6 {
	margin-top: -6px !important;
}

.asset-type-heading {
	position: relative;
	top: 10px;
	left: 33%;
}

.icon-info-position {
	margin-top: -3px;
}

.popover-info-icon {
	font-size: 10px;
	position: relative;
}

.react-numeric-input b {
	background: white !important;
}
.abcpFilters input {
	width: 89%;
	padding: 6px;
	margin: 0px 0;
	border-radius: 4px;
	font-size: 16px;
	input[type='text'] {
		padding: 5px;
		box-sizing: border-box;
	}
}

.order-card-header {
	background-color: var(--feature-card-bg);
}

.negotiation-modal {
	.badge {
		padding: 4px 12px !important;
		line-height: 23px;
	}
	.badge-style {
		&:after {
			margin-left: 8px;
			padding-right: 8px;
			color: $white;
			content: var(--bs-breadcrumb-divider, '|');
			font-weight: $font-weight-normal;
		}
	}
	.yellow-color {
		color: var(--yellow-color);
	}
	.card-col-seperator {
		&:after {
			margin: 0 8px;
			display: flex;
			color: $white;
			content: '';
			border-right: 1px solid var(--grey-5);
			height: 100%;
		}
	}
	.negotiation-card {
		background: var(--negotiation-card-bg);
	}
	.negotiation-received {
		background: var(--negotiation-received);
	}
	.negotiation-sent {
		background: var(--negotiation-sent);
	}
	.negotiation-tabs {
		.nav-item {
			padding: 10px 16px !important;
		}
		.card-header-tabs {
			margin: 0 !important;
		}
	}
	.preview-box {
		.preview-body {
			ul {
				li {
					.label-field {
						padding-left: 20px;
						font-size: 12px;
					}
					.value {
						font-size: 14px;
						color: var(--custom-control-border-hover);
					}
					.summary-display {
						position: relative;
						width: 100%;
						padding-left: 7.5px;
						padding-right: 7.5px;
					}
					&:nth-child(5n) {
						.value {
							border-right: 0 !important;
						}
						.summary-display {
							border-right: 0 !important;
						}
					}
					&:nth-child(3n) {
						.value {
							border-right: 1px solid var(--border-color);
						}
					}
					&:nth-child(n) {
						.summary-display {
							border-right: 1px solid var(--border-color);
						}
					}
				}
			}
		}
	}
}

// Badge Color
$badge-color-list: coral, leaf-green;
@each $badge-color in $badge-color-list {
	.badge-#{$badge-color} {
		background: var(--#{$badge-color}) !important;
		color: $white;
	}
}
// Tag Bg Color
$tag-color-list: purple, green, grey;
@each $tag-color in $tag-color-list {
	.tag-#{$tag-color} {
		background: var(--tag-#{$tag-color}) !important;
		color: #222222;
	}
}

.badge-order {
	min-width: 68px;
	max-width: 68px;
	width: 68px;
	padding: 2px 7px;
	height: 36px;
	justify-content: center;
	margin: auto;
	margin-top: 2px;
	.badge-value {
		font-size: 14px;
		line-height: 18px;
	}
}

.tag-order-type {
	font-size: 10px;
	padding: 0 3px;
	border-radius: 2px;
	position: relative;
	left: -5px;
	transform: rotate(270deg);
	height: 20px;
	width: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.activity-log-component {
	.card-body {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		height: 200px;
	}
}

// master detail table
.ag-details-row {
	padding-top: 9px !important;
	padding-bottom: 9px !important;
}

// filter menu
.ag-menu {
	z-index: 11112 !important;
}

// dropdown inside cell
.ag-show-dropdown {
	overflow: visible !important;
}
.ag-row {
	z-index: 0;
}

// no border
.no-border.ag-theme-alpine .ag-root-wrapper {
	border: 0 !important;
}
.no-border.ag-theme-alpine-dark .ag-root-wrapper {
	border: 0 !important;
}

.ag-row.ag-row-position-absolute.ag-row-focus {
	display: block;
	z-index: 2;
}

.highlight {
	background-color: grey !important;
}

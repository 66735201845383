.contextMenu {
	position: absolute;
	z-index: 99999999;
	width: 200px;
	box-shadow: 0 4px 5px 3px #ddd;
	background: #fff;
}

.contextMenu .menuOptions {
	list-style: none;
	padding: 16px 0 0;
}

.contextMenu .menuOptions .menuOption {
	font-weight: 500;
	font-size: 14px;
	padding: 7px 40px 7px 20px;
	cursor: pointer;
}

.contextMenu .menuOptions .menuOption:hover {
	box-shadow: inset 0 0 10px #dedede;
	background: #eee;
}

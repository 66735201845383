#outer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--login-outer-bg);
}

#outer #login-container {
	// color: #663399;
	// padding: 15px 25px;
	width: 100%;
	max-width: 1040px;
	position: absolute;
	transform: translate(-50%, -50%);
	left: 50%;
	top: 50%;
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
	.form-control {
		border: 1px solid var(--login-input-border-color) !important;
	}
}

.card-login {
	display: flex;
	flex-direction: row;
	min-height: 576px;
	border: 0;
	.left {
		width: 560px;
		border-radius: 6px 0 0 6px;
		border-right: 1px solid var(--right-border-color);
	}
	.right {
		background: var(--common-header-bg);
		flex: 1 1 0;
		padding: 58px 75px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		border-radius: 6px;

		.heading {
			color: var(--login-header-color);
			border-color: var(--login-header-border) !important;
		}
		label {
			color: var(--login-label-color);
		}
		.form-control {
			&::placeholder {
				color: #666666;
			}
		}
		.get-code-link {
			color: var(--btn-secondary);
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	.form-control:disabled {
		background: var(--input-disabled-bg);
	}

	.form-control[type='email'],
	.form-control[type='text'],
	.form-control[type='password'],
	.form-control[readonly],
	.form-control {
		&::placeholder {
			color: var(--login-input-default);
		}
		&:active,
		&:focus {
			color: var(--login-input-active);
			background: transparent;
		}
		color: var(--login-input-filled);
	}
}

.left-w-100 {
	width: 100vw !important;
}

.left {
	padding: 38px;
	background-color: var(--header-logo-bg);
	display: flex;
	justify-content: center;
	align-items: center;
}

.login-carousel {
	flex: 1;
	height: 100%;
	display: flex;
	.site-link {
		color: #6592db;
	}
	.carousel-inner {
		height: 100%;
		display: flex;
	}
	.img-wrap {
		width: 260px;
		height: 220px;
		margin: 0 auto;
	}
	.carousel-caption {
		position: relative;
		right: auto;
		bottom: auto;
		left: auto;
		padding: 0;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		color: #a8a8a8;
		height: 95%;
		h3 {
			font-size: 40px;
			font-weight: 700;
			margin-bottom: 4px;
		}
	}
	.carousel-control-prev,
	.carousel-control-next {
		display: none;
	}
	.carousel-indicators {
		margin-bottom: 0;
		li {
			width: 12px;
			height: 12px;
			background-color: var(--carousal-indicator-bg);
			border: 1px solid var(--carousal-indicator-bg);
			border-radius: 50%;
			&.active {
				background-color: var(--carousal-indicator-active-bg);
				border-color: var(--carousal-indicator-active-border);
			}
		}
	}
	.image-title {
		font-size: 30px;
		color: #00a867;
	}
}
.login-logo-wrapper {
	color: var(--login-logo-color);
}
.image-description {
	color: var(--image-description-color);
}

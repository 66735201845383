$fs_navbar_width: 75px;
$full_width: 100%;

#fs-parent-container {
	height: 100%;
	width: calc((#{$full_width} - #{$fs_navbar_width}));
	width: -webkit-calc((#{$full_width} - #{$fs_navbar_width}));
	width: -moz-calc((#{$full_width} - #{$fs_navbar_width}));
	float: right;
}

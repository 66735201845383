.client-scoring {
	.factory-weight-alert {
		width: 271px;
		margin: auto;
		margin-top: 2px 0px 2px 0px;
		padding: 6px 15px 6px 10px;
	}
	.fw-total {
		text-align: right;
		padding: 5px 0px 5px 0px;
	}
	.edit-factors {
		position: relative;
		top: 3px;
	}
	.factory-btn-section {
		position: absolute;
		right: 0;
	}

	.factory-date-range {
		font-family: cursive;
		vertical-align: middle;
		margin-right: 20px;
	}
	.space-around {
		justify-content: space-between;
		align-items: center;
	}
	#client_scoring {
		.amcharts-Container.amcharts-Label.amcharts-AxisLabel {
			fill: var(--grey-12);
		}
		.amcharts-Sprite-group.amcharts-Grid-group {
			stroke: var(--grey-12);
		}
		.amcharts-Container.amcharts-Label {
			fill: var(--grey-12);
		}
	}
}

.decision-making {
	#asset-exposure-custom,
	#asset-duration-custom,
	#counterparty-exposure-custom {
		.amcharts-Container.amcharts-Label.amcharts-AxisLabel {
			fill: var(--grey-12);
		}
		.amcharts-Sprite-group.amcharts-Grid-group {
			stroke: var(--grey-12);
		}
		.amcharts-Container.amcharts-Label {
			fill: var(--grey-12);
		}
	}
}
.h-400 {
	height: 400px;
}

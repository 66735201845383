.widget {
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
	border-radius: 4px;
	background: var(--white);
	margin: 0.8rem 0;
	.section {
	}
}

.draggable-box {
	background: var(--grey-7);
	//padding: 2px 0 0 2px; // Removed to support blurry box-shadow
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18);
	overflow: visible; // Changed to visible to show dropdown in market trade data
	.react-table-header {
		box-shadow: none;
		overflow: visible; // Added to show dropdown in market trade data
		.ReactTable {
			padding-bottom: 10px;
		}
	}
}

.modal-dialog {
	height: 100%;
	min-height: auto;
	margin-bottom: 2rem;
	margin-top: 2rem;
	max-height: calc(100% - 4rem);
	.modal-content {
		background: var(--modal-bg);
		border-radius: 0.6rem;
		max-height: 100%;
		.modal-header {
			padding: 1rem 1.2rem;
		}
		.modal-body {
			overflow-y: auto;
			height: 100%;
			&.tab-fill {
				.tab-content {
					display: flex;
					flex-direction: column;
					height: 100%;
					.tab-pane {
						flex: 1;
						display: flex;
						flex-direction: column;
						//padding-bottom: 1.5rem;
					}
				}
			}
		}
		&.modal-full-width {
			width: 90%;
			max-width: none;
		}
		.modal-mask {
			textarea {
				resize: none;
			}
			.mask {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: var(--component-loader-overlay);
				z-index: 10;
			}
			.mask-footer {
				position: absolute;
				bottom: 0;
				width: 100%;
				background: var(--modal-bg);
				text-align: center;
				padding: 2rem 0;
				box-shadow: 0 -1px 5px 0px rgba(0, 0, 0, 0.25);
				z-index: 11;
			}
		}
	}

	&.locate-security-modal {
		max-width: 35rem;

		&.modal-sm {
			.modal-content {
				max-height: 20rem;
			}
		}

		.modal-content {
			max-height: 35rem;
		}

		// .ag-theme-alpine-dark {
		// 	& *,
		// 		.ag-cell:last-child {
		// 			border: none;
		// 		}
		// }

		// .ag-theme-alpine-dark {
		.ag-root-wrapper {
			border: none;
			background-color: transparent;

			.ag-header {
				background-color: transparent;
				// border-bottom: none;

				.ag-pinned-right-header {
					border: none;
				}
			}

			.ag-row {
				// background-color: var(--react-table-bg-even);
				// &-odd {
				// 	background-color: var(--ag-background-color, #181d1f);
				// }

				.ag-cell {
					border-left: none !important;
				}
			}

			.ag-header-row,
			.ag-pinned-right-header {
				.ag-header-cell-resize,
				.ag-header-row {
					&:after {
						width: 0;
					}
				}
			}

			.ag-center-cols-container {
				width: 100% !important;
			}
		}
		// }

		.filter-wrapper {
			width: 40px;
			overflow: hidden;

			ul {
				padding: 0;
				overflow-y: scroll;
				width: 100%;
				padding-right: 17px;
				box-sizing: content-box;

				li {
					list-style: none;
					width: 20px;

					span {
						cursor: pointer;
						padding: 0px 10px;
						border-radius: 4px;
						&.active {
							background-color: var(--primary);
						}
					}
				}
			}

			+ div {
				.card {
					box-shadow: none !important;
				}
			}
		}
	}
}

.data-visualization-modal {
	&.modal-xl {
		.modal-content {
			height: 100% !important;
		}
	}

	.modal-content {
		height: 100% !important;
	}

	.data-visualization-modal-grid {
		::-webkit-scrollbar {
			width: 8px;
		}

		/* Track */
		::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		}

		/* Handle */
		::-webkit-scrollbar-thumb {
			background: rgba(112, 112, 112, 0.8);
			-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
			box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
		}
		::-webkit-scrollbar-thumb:window-inactive {
			background: rgba(112, 112, 112, 0.8);
		}

		.ag-body-horizontal-scroll,
		.ag-body-horizontal-scroll-viewport,
		.ag-body-horizontal-scroll-container {
			max-height: 8px !important;
			min-height: 8px !important;
			height: 8px !important;
		}

		.ag-center-cols-viewport {
			overflow-x: hidden !important;
		}
	}
}

.modal-scrollable {
	// make modal body scrollable
	.modal-content {
		height: 100%;
	}
}

.modal-backdrop:nth-of-type(2n) {
	// 2nd Modal Backdrop
	z-index: 1050;
}

.modal-customize-menu {
	.modal-content {
		border: 0;
		border-radius: 6px;
		height: calc(100vh - 60px);
	}
	.customize-menu-container {
		box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.7);
		border-radius: 6px;
	}
	.menu-list-box {
		max-width: 370px;
		box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.29);
		// box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 1);
		.scrollbar-container {
			height: auto;
		}
	}
	.menu-list-title {
		font-size: 18px;
		font-weight: 600;
		padding: 22px 30px;
	}
	.create-new-menu-item {
		color: var(--menu-config-green-1);
		border-top: 1px solid var(--menu-item-border-color);
	}
	.menu-item-title {
		color: var(--menu-item-title-color);
	}
	.menu-list-item {
		border-top: 1px solid var(--menu-item-border-color);
		border-bottom: 1px solid var(--menu-item-border-color);
		+ .menu-list-item {
			border-top: 0px;
		}
		&:last-child {
			border-bottom: 0;
		}
	}
	.active-menu-item {
		background-color: var(--menu-config-bg-purple);
		.menu-item-title {
			color: var(--menu-item-active-title-color);
			font-weight: 600;
		}
	}
	.dropdown .dropdown-toggle {
		height: auto;
		color: var(--more-action-dropdwon-color);
	}
	.max-char-length {
		color: var(--menu-item-title-color);
	}
	.customize-close-icon {
		cursor: pointer;
		position: absolute;
		top: 0;
		right: -33px;
		padding: 10px;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		background-color: #222;
		color: var(--menu-config-modal-close-color);
	}
	.navigation-active-chk {
		color: var(--navigation-active-checkmark-color);
	}
	.selected-menu-subheader {
		color: var(--selected-menu-subheader-color);
		// border-top: 1px solid var(--border-color) !important;
		border-bottom: 1px solid var(--border-color) !important;
	}
	.selected-menu-item {
		color: var(--selected-menu-item-color);
	}
	.arrow-add-remove {
		color: #a3a3af;
	}
	.selected-menu-list {
		.selected-menu-item {
			&:last-child {
				padding-bottom: 35px !important;
			}
			.selected-menu-item-icon {
				color: var(--selected-menu-item-icon-color);
			}
			.available-menu-item-inner {
				color: var(--selected-menu-subheader-color);
				.menu-item-icon {
					color: var(--available-menu-item-icon-color);
				}
				.menu-label {
					color: var(--selected-menu-item-lable-color);
				}
			}
		}
	}
}

.modal-customize-menu-applied {
	.modal-content {
		// border: 0;
		border-radius: 6px;
		height: 300px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.feature-menu-modal {
	width: 100%;
	max-width: none !important;
	margin: 0;
	.modal-content {
		min-height: 100vh;
		border-radius: 0;
		padding: 15px 68px 15px 90px;
		// padding: 38px 68px 46px 118px;
		background-color: var(--feature-modal-bg);
		@include media-breakpoint-down(lg) {
			padding: 38px;
		}
	}
	.modal-header,
	.close {
		color: var(--feature-modal-header-color);
	}
	.modal-title {
		@media screen and (min-width: 1920px) {
			font-size: 32px !important;
		}
	}
}

.locate-new-request-modal {
	max-width: 494px;
	.modal-content {
		height: 482px;
	}
}
.locate-authorizer-new-request-modal {
	max-width: 748px;
	.modal-content {
		height: 502px;
	}
}

.add-inventory-popoup-large {
	max-width: 780px;
	.modal-content {
		height: 502px;
	}
}
.add-inventory-popoup-small {
	max-width: 550px;
	.modal-content {
		height: 502px;
	}
}
.feature-card {
	background: var(--feature-card-bg);
	min-width: 330px;
	margin-right: 22px;
	margin-bottom: 27px;
	box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.29);
	position: relative;
	border-radius: 4px;
	@media screen and (min-width: 1920px) {
		padding-top: 35px !important;
	}
}

.feature-number-tag {
	background: var(--feature-number-tag-bg);
	display: inline-flex;
	align-items: center;
	padding: 7px 12px;
	float: right;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	color: var(--feature-number-tag-color);
	font-size: 12px;
	@media screen and (min-width: 1920px) {
		font-size: 16px;
	}
}

.feature-image-box {
	&.text-orange {
		color: var(--feature-text-orange);
	}
	&.text-green {
		color: var(--feature-text-green);
	}
	&.text-torquoise {
		color: var(--feature-text-torquoise);
	}
	&.text-peach {
		color: var(--feature-text-peach);
	}
	&.text-blue {
		color: var(--feature-text-blue);
	}
	&.text-purple {
		color: var(--feature-text-purple);
	}
	&.text-lightgreen {
		color: var(--feature-text-lightgreen);
	}
	&.text-pink {
		color: var(--feature-text-pink);
	}
	&.text-cyan {
		color: var(--feature-text-cyan);
	}
	.img-wrap {
		width: 60px;
		height: 45px;
		margin: 0 auto;
		@media screen and (min-width: 1920px) {
			width: 90px;
			height: 60px;
		}
	}
	.feature-title {
		font-size: 15px;
		font-weight: 600;
		text-align: center;
		padding-top: 12px;
		@media screen and (min-width: 1920px) {
			font-size: 25px;
			padding: 16px 0;
		}
	}
}

.feature-list {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 0;
	max-height: 90px;
	overflow: hidden;
	.feature-list-item {
		flex: 1 0 50%;
		padding: 8px 20px;
		border-top: 1px solid var(--feature-list-item-border-color);
		color: var(--feature-list-item-svg-color);
		position: relative;
		overflow: hidden;
		height: 45px;
		display: flex;
		align-items: center;
		&:nth-child(2n-1) {
			border-right: 1px solid var(--feature-list-item-border-color);
		}
		&-icon {
			@media screen and (min-width: 1920px) {
				display: block;
				width: 40px;
				height: 40px;
				svg {
					width: 100%;
					height: 100%;
				}
			}
		}
		&-label {
			color: var(--feature-list-item-color);
			padding-left: 8px;
			display: block;
			font-size: 12px;
			line-height: 18px;
			@media screen and (min-width: 1920px) {
				font-size: 16px;
			}
		}
	}
}

.feature-list-item-less,
.feature-list-item-more {
	border-radius: 4px;
	background-color: #298a83;
	font-size: 12px;
	padding: 0px 4px;
	position: absolute;
	left: 50%;
	transform: translateX(-10px);
	color: $white;
	font-weight: 600;
	cursor: pointer;
	transition: opacity 1s linear;
	opacity: 1;
	z-index: 1;
	&.hide-more {
		opacity: 0;
	}
}

.feature-list-item-less {
	bottom: 76px;
}

.feature-list-item-more {
	bottom: -13px;
}

.feature-add-ribbon,
.feature-beta-ribbon,
.feature-soon-ribbon {
	background-color: var(--add-ribbon-bg);
	color: var(--add-ribbon-color);
	padding: 2px;
	width: 150px;
	position: absolute;
	font-size: 12px;
	line-height: 1;
	font-weight: 600;
	text-align: center;
	transform: rotate(-45deg);
	right: -65px;
	padding-right: 23px;
}

.feature-add-ribbon {
	background-color: var(--add-ribbon-bg);
	color: var(--add-ribbon-color);
}

.feature-beta-ribbon {
	background-color: var(--beta-ribbon-bg);
	color: var(--beta-ribbon-color);
}

.feature-soon-ribbon {
	background-color: var(--soon-ribbon-bg);
	color: var(--soon-ribbon-color);
}

.borrow-confirm-modal {
	@include media-breakpoint-up(xs) {
		max-width: 420px;
	}
}

.trade-modal {
	.modal-footer {
		box-shadow: 0px -2px 6px #00000029;
		border: 0;
	}
}

.order-entry-modal {
	.datepicker-wrapper {
		.custom-control-label {
			margin-bottom: 0;
		}
	}
	.order-entry-dropdown {
		.dropdown-menu {
			min-width: 200px;
		}
		.dropdown-item {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	.upload-basket-btn {
		position: absolute;
		right: 0;
		top: 0;
	}
}

.modal-btn-size {
	margin-right: 2em !important;
	margin-bottom: 2em !important;
}

.btn-width-expanded {
	width: 98% !important;
	max-width: 98% !important;
}
.btn-width-compressed {
	width: 73% !important;
	max-width: 73% !important;
}

.repo-cards,
.dashboard-cards {
	margin-top: 1rem;
	margin-bottom: 1rem;
	flex-shrink: 0;
	.col-6 {
		display: flex;
		flex: 1;
		.card {
			cursor: pointer;
			padding: 0.5rem;
			width: 100%;
			color: var(--grey-9);
			background: var(--card);
			&.active {
				background: var(--primary);
				color: var(--white);
			}
			span {
				line-height: normal;
				white-space: nowrap;
			}
			&:hover {
				//transform: scale(1.03);
				transition: 0.25s all linear;
				box-shadow: 0px 2px 6px 3px rgba(0, 0, 0, 0.18) !important;
			}
		}
	}
}

.card-currency {
	position: absolute;
	right: 6px;
	top: 4px;
	font-size: 0.6875rem;
}
.pricing-btn-close-position {
	z-index: 1;
	max-width: fit-content;
	margin: auto;
}

// .dashboard-grouping-wrapper {
// 	padding: 0 !important;
// 	margin: 0 !important;
// 	border: none;
// 	margin-top: 0.25rem !important;
// 	width: 410px !important;
// 	left: auto !important;
// 	right: 0 !important;
// 	.dashboard-grouping {
// 		display: flex !important;
// 		background: var(--dropdown-menu-bg);
// 		z-index: 10;
// 		border-radius: 4px;
// 		box-shadow: 0 0 4px rgba(0, 0, 0, 0.18);
// 		width: 100%;
// 	}
// }

.dragable-container {
	font-size: 14px;
	flex: 1;
	overflow: auto;
	padding: 1rem 1rem 0 1rem;
	.card {
		background: transparent;
		box-shadow: none !important;
	}
	.list-group-item {
		border: none;
		background: transparent;
	}

	.dragable-item {
		border-bottom: 1px solid var(--grey-7);
		&:last-child {
			border-bottom: none;
		}
	}

	a.drag {
		color: var(--grey-1) 3;
		margin: -7px 16px 0 5px;
		display: inline-block;
		vertical-align: middle;
	}
}

.list-group-item {
	background: var(--dropdown-menu-bg);
}

.portfolio-upload-wrapper {
	border: 1px dashed var(--secondary);
	border-radius: 0.25rem;
	flex: 1 1 120px;
	display: flex;
	min-height: 120px;
	background-color: var(--body-bg);
	.portfolio-upload {
		background: var(--white);
		border-radius: 0.25rem;
		margin: 0.875rem;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		.media {
			.media-right {
				padding-left: 1.25rem;
				font-weight: medium;
			}
		}
	}
}

.portfolio-file-table {
	border: 1px solid var(--grey-6);
	border-radius: 4px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;
	.header {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid var(--grey-6);
		padding: 0.3rem 0 0.4rem;
	}
	.body {
		//overflow: auto;
		flex: 1;
		display: flex;
		flex-direction: column;
		max-height: 100%;
		transition: 0.3s all ease;
		height: 100%;
		> div {
			&:last-child {
				border-bottom: none;
			}
			&:hover {
				transition: 0.5s all ease;
				background: var(--text-2);
				svg {
					color: var(--secondary);
				}
				.arrow-hover {
					.hover {
						display: flex;
					}
					.default {
						display: none;
					}
				}
			}
			.arrow-hover {
				.hover {
					display: none;
				}
				.default {
					display: flex;
				}
			}
		}
	}
}

.portfolio-header {
	> div:first-child {
		min-width: 22% !important;
	}
	> div:not(:last-child) {
		min-width: 14%;
	}
}

.pf-status-dropdown {
	left: auto !important;
	right: 0 !important;
	max-height: 310px !important;
	transform: translate3d(0px, 37px, 0) !important;
	overflow: hidden !important;
	padding-top: 0;
	padding-bottom: 0;
	.dropdown-item {
		padding: 12px 20px;
	}
	.status-wrapper {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		max-height: 310px;
	}
}

.gradient-border {
	// --borderWidth: 1px;
	// position: relative;
	// border-radius: var(--borderWidth);
	background: linear-gradient(60deg, #5073b8, #1098ad, #07b39b, #6fba82);
	animation: animatedgradient 3s ease alternate infinite;
	background-size: 300% 300%;
}

.gradient-border:after {
	// content: "";
	// position: absolute;
	// top: calc(-1 * var(--borderWidth));
	// left: calc(-1 * var(--borderWidth));
	// height: calc(100% + var(--borderWidth) * 1);
	// width: calc(100% + var(--borderWidth) * 1);
	// background: linear-gradient(60deg, #5073b8, #1098ad, #07b39b, #6fba82);
	// border-radius: calc(1 * var(--borderWidth));
	// z-index: -1;
	// animation: animatedgradient 3s ease alternate infinite;
	// background-size: 300% 300%;
}

@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.rc-slider-mark-text {
	left: 10.73333% !important;
}

.locate-searchbox,
.locate-search-resultbox {
	.ag-row:not(.ag-row-first) {
		cursor: pointer;
	}
}
.ag-center-cols-viewport {
	overflow-x: hidden !important;
}

.FsLoader {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ffffff80;
	z-index: 999999;

	svg {
		margin: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.ajax-loader {
	position: fixed;
	width: 100%;
	height: 4px;
	top: 0;
	left: 0;
	background-color: var(--ajax-loader-bg);
	z-index: 999999;
	.ajax-animator {
		position: relative;
		top: 0;
		right: 0;
		width: 200px;
		height: 4px;
		background-color: var(--ajax-animator-color);
		animation: crawler 2s infinite;
		border-radius: 40px;
		box-shadow: -2px -1px 3px 0px #000;
	}
}

@keyframes crawler {
	0% {
		left: 0px;
	}
	100% {
		left: 100%;
	}
}

.history-tl-container {
	// margin: auto;   // Basket Negotiation
	display: block;
	position: relative;
	ul.tl {
		margin: 0.6rem 0;
		padding: 0;
		display: inline-block;
		li {
			list-style: none;
			margin: auto;
			min-height: 50px;
			position: relative;
			&:last-child {
				&:after {
					border-left: 0;
				}
			}
			&::before {
				position: absolute;
				left: -15px;
				top: 6px;
				content: ' ';
				border-radius: 500%;
				background: var(--grey-4);
				height: 0.5rem;
				width: 0.5rem;
				transition: all 500ms ease-in-out;
				z-index: 10;
			}
			&:after {
				border-left: 1px dashed $light;
				content: '';
				position: absolute;
				width: 1px;
				height: 100%;
				top: 11px;
				left: -11px;
				z-index: 0;
			}
			.item-title {
				font-size: 0.8125rem;
				font-weight: 600;
				max-width: 250px;
			}
			.item-detail {
				color: var(--grey-4);
				font-size: 0.875rem;
				max-width: 250px;
			}
			&.green {
				&:before {
					background: $success;
				}
			}
			&.red {
				&:before {
					background: $danger;
				}
			}
			&.primary {
				&:before {
					background: $primary;
				}
			}
			&.dark {
				&:before {
					background: $dark;
				}
			}
		}
	}
}

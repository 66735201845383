.Table__itemCount {
	/* margin-top: 10px; */
	font-size: 14px;
}

.Table__pagination {
	display: flex;
	justify-content: center;
	padding: 20px 10px;
}

.Table__pageButton {
	font-size: 15px;
	outline: none;
	border: none;
	background-color: transparent;
	cursor: pointer;
	color: var(--grey-5);
}

.Table__pageButton:disabled {
	cursor: not-allowed;
	color: gray;
}

.Table__pageButton--active {
	color: #5681b9;
}
.selected-page {
	font-size: 15px;
	cursor: pointer;
	border-radius: 4px;
	color: #ffffff !important;
	background-color: #092e5d;
}

// Bootstrap v4.3.1 (https://getbootstrap.com/)
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700,900&display=swap');
@import '../node_modules/bootstrap/scss/functions';

// TypeAhead
@import '../node_modules/react-bootstrap-typeahead/css/Typeahead.css';

// React Grid Layout
@import '../node_modules/react-grid-layout/css/styles.css';
@import '../node_modules/react-resizable/css/styles.css';

// Ag-grid Layout
@import '../node_modules/ag-grid-community/dist/styles/ag-grid.css';
@import '../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css';
@import '../node_modules/ag-grid-community/dist/styles/ag-theme-alpine-dark.css';

@import '../node_modules/dmn-js/dist/assets/dmn-js-drd.css';
@import '../node_modules/dmn-js/dist/assets/diagram-js.css';
@import '../node_modules/dmn-js/dist/assets/dmn-js-shared.css';
@import '../node_modules/dmn-js/dist/assets/dmn-js-decision-table-controls.css';
@import '../node_modules/dmn-js/dist/assets/dmn-js-decision-table.css';
@import '../node_modules/dmn-js/dist/assets/dmn-js-literal-expression.css';
@import '../node_modules/dmn-js/dist/assets/dmn-font/css/dmn.css';

// Font Awesome Icon
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/v4-shims';

//Custom Variables.  It should be after bootstrap function and before bootstrap variables.
@import 'styles/variables';
@import 'styles/bs-variables';
@import 'styles/mixin';

@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/root';
@import '../node_modules/bootstrap/scss/reboot';
@import '../node_modules/bootstrap/scss/type';
@import '../node_modules/bootstrap/scss/images';
@import '../node_modules/bootstrap/scss/code';
@import '../node_modules/bootstrap/scss/grid';
@import '../node_modules/bootstrap/scss/tables';
@import '../node_modules/bootstrap/scss/forms';
@import '../node_modules/bootstrap/scss/buttons';
@import '../node_modules/bootstrap/scss/transitions';
@import '../node_modules/bootstrap/scss/dropdown';
@import '../node_modules/bootstrap/scss/button-group';
@import '../node_modules/bootstrap/scss/input-group';
@import '../node_modules/bootstrap/scss/custom-forms';
@import '../node_modules/bootstrap/scss/nav';
@import '../node_modules/bootstrap/scss/navbar';
@import '../node_modules/bootstrap/scss/card';
@import '../node_modules/bootstrap/scss/breadcrumb';
@import '../node_modules/bootstrap/scss/pagination';
@import '../node_modules/bootstrap/scss/badge';
@import '../node_modules/bootstrap/scss/jumbotron';
@import '../node_modules/bootstrap/scss/alert';
@import '../node_modules/bootstrap/scss/progress';
@import '../node_modules/bootstrap/scss/media';
@import '../node_modules/bootstrap/scss/list-group';
@import '../node_modules/bootstrap/scss/close';
@import '../node_modules/bootstrap/scss/toasts';
@import '../node_modules/bootstrap/scss/modal';
@import '../node_modules/bootstrap/scss/tooltip';
@import '../node_modules/bootstrap/scss/popover';
@import '../node_modules/bootstrap/scss/carousel';
@import '../node_modules/bootstrap/scss/spinners';
@import '../node_modules/bootstrap/scss/utilities';
@import '../node_modules/bootstrap/scss/print';
@import '../node_modules/react-table/react-table.css';
@import '../node_modules/react-datepicker/dist/react-datepicker.css';
@import '../node_modules/react-perfect-scrollbar/dist/css/styles.css';

@import 'styles/utilities';
@import 'styles/form-elements';
@import 'styles/common';
@import 'styles/darkMode';

// Common Components
@import 'styles/common/left-nav';
@import 'styles/common/pivot-table';
@import 'styles/common/react-table';
@import 'styles/common/modals';
@import 'styles/common/fsTreeMap';
@import 'styles/common/fsLoader';
@import 'styles/common/fsPagination';
@import 'styles/common/timeline';
@import 'styles/common/flyout-menu';
@import 'styles/common/draggable';
@import 'styles/common/fsContextMenu';
@import 'styles/common/rc-slider';
@import 'styles/common/ag-grid';
@import 'styles/common/status';
@import 'styles/common/password-input';
@import 'styles/common/step-progress-bar';
@import 'styles/common/accordion';
@import 'styles/common/async-type-ahead';

// Pages
@import 'styles/pages/portfolio-manager/portfolio';
@import 'styles/pages/dashboard/dashboard';
@import 'styles/pages/marketplace/mp-widget';
@import 'styles/pages/profile-options/account-settings';
@import 'styles/pages/calendar/calendar';
@import 'styles/pages/trading-queue/trading-queue';
@import 'styles/pages/repo/repo';
@import 'styles/pages/decision-making/decision';
@import 'styles/pages/indexPricer/indexPricer';
@import 'styles/pages/reports-dashboard/reports-dashboard';
@import 'styles/pages/fmWidget/fmWidget';
@import 'styles/pages/locate/locate-authorizer/locate-authorizer';
@import 'styles/pages/trading-queue/trade-ticket';
@import 'styles/pages/dashboard/orderDetail';
@import 'styles/pages/other';
@import 'styles/pages/alp/alp';
@import 'styles/pages/sblSecurity/sblSecurityMainPage';
// @import 'styles/pages/marginAnalysis/marginAnalysis';

.reports-dashboard {
	.popup {
		float: right;
		position: relative;
		display: inline-block;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	/* The actual popup */
	.popup .popuptext {
		//width: max-content;
		// background-color: #fff;
		background: var(--modal-bg);
		color: #000000;
		border-radius: 6px;
		padding: 8px 0px 20px 0;
		position: absolute;
		z-index: 1000;
		top: 22px;
		right: 0;
		visibility: hidden;
		width: 300px;
		border: 1px solid #d3d3d3;
		//height: 300px;
	}
	.popup:hover {
		.popuptext {
			visibility: visible;
			-webkit-animation: fadeIn 1s;
			animation: fadeIn 1s;
		}
	}
	.wrappper {
		// justify-content: flex-end;
		margin-left: 33px;
		margin-top: 20px;
	}
	.wrappper > * {
		flex: 1 1 33%;
		//margin-left: 5px;
		// margin: 10px;
	}
	@-webkit-keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	.table-badge {
		height: 40px;
		width: 100px;
		padding: 5px 0;
		border-radius: 2px;
		font-size: 16px;
		font-weight: medium;
		text-align: center;
		//background-color: var(--btn-secondary);
		border-color: var(--white);
		color: var(--secondary);
	}
	.media-left {
		padding-bottom: 20px;
	}
	.table-badge-layout {
		padding: 3px 12px;
		border: 1px;
		border-radius: 30px;
		background-color: var(--btn-secondary);
		border-color: var(--btn-secondary);
		color: var(--btn-secondary-color);
	}
}

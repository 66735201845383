.fm-widgets {
	font-size: 1.375rem !important;
	font-weight: 600 !important;
	.popup {
		float: right;
		position: relative;
		display: inline-block;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.popup .popuptext {
		min-width: 200px;
		text-overflow: ellipsis;
		color: var(--grey-9);
		background: var(--card);
		border-radius: 6px;
		border: 1px solid lightgray;
		position: absolute;
		z-index: 1000;
		top: 22px;
		right: 10px;
		font-weight: 600;
		width: max-content;
		padding: 10px;
	}

	.openwidget {
		max-height: 220px;
		overflow: auto;
	}

	.widgets-list {
		padding-top: 5px;
		padding-bottom: 5px;
		color: var(--grey-12);
	}

	.widgets-list:hover {
		font-size: 14px;
		font-weight: bold;
		transform: scale(1.2);
		background-color: var(--primary);
	}

	.delete-widget {
		color: red;
		opacity: 0.7;
	}
	.delete-widget:hover {
		transform: scale(1.2);
		opacity: 1;
	}

	.openwidget ul {
		list-style: none;
	}

	#myPopup > .title {
		margin-left: 18px;
		position: relative;
		top: 5px;
	}
	.title {
		color: var(--primary);
		padding: 10px 10px 10px 0px;
	}

	.m-t-0 {
		margin-top: 0px;
	}

	.p-10 {
		padding: 10px;
	}

	.m-l-20 {
		margin-left: 20px;
	}

	.popup .show {
		visibility: visible;
		-webkit-animation: fadeIn 1s;
		animation: fadeIn 1s;
	}

	@-webkit-keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	.information-block {
		justify-content: space-between;
		align-items: center;
	}

	ul li {
		color: var(--grey-9);
		background: var(--card);
		position: relative;
		min-width: 100px;
		line-height: 30px;
		height: 30px;
		list-style: none;
		margin-right: 2px;
	}

	ul li ul {
		display: none;
	}

	ul li:hover ul {
		display: block;
	}

	ul li:hover .rightArrow {
		display: inline;
	}

	ul li ul.subItems {
		background-color: #fff;
		color: #212529;
		position: absolute;
		top: -5px;
		left: 148px;
		margin-top: 0px;
	}

	ul li ul {
		list-style: none;
	}

	ul li ul li {
		padding: 0px 10px 0px 8px;
	}

	li ul {
		width: max-content;
		color: #fff;
		border-radius: 6px;
		border: 1px solid lightgray;
		padding: 8px 0px 12px 0px;
	}

	.rightArrow {
		display: none;
		right: 9px;
		position: absolute;
	}

	.fm-svg-icon {
		position: inherit !important;
	}
	.p-25 {
		padding: 25px;
	}
	#fm-toolbar-wrapper #fm-toolbar li.fm-divider {
		display: none;
	}
	.margin-auto {
		margin: auto;
	}
	.p-20 {
		padding: 20px;
	}
	.float-right {
		float: right;
	}
	.toggle-btn {
		position: relative;
		top: -30px;
		left: 10px;
	}
	.widget-save-btn {
		position: relative;
		top: -30px;
		left: 14px;
	}
	.p-t-5 {
		padding-top: 5px;
	}
	.p-v-5 {
		padding-left: 5px;
		padding-right: 5px;
	}
	.widgets-close-icon {
		position: relative;
		bottom: 3px;
		cursor: pointer;
	}
}
.fm-widget-toolbar-button {
	background: var(--white);
	color: #df3800;
	text-transform: uppercase;
	cursor: pointer;
	font-size: 27px;
	padding: 0px 5px;
	font-weight: 600;

	&.widget-toolbar {
		right: 35px;
		top: 1px;
		margin: 0px;
		border: none;
		padding: 0px 5px;
		font-size: 28px;
		background: transparent;
	}
}
.p2p-popup-widget {
	position: absolute;
	bottom: 90px;
	right: 200px;
}

.step-progressbar {
	li {
		padding-bottom: 50px;
		display: flex;
		position: relative;
		line-height: 20px;
		cursor: pointer;
		&:before {
			content: '';
			width: 1px;
			height: 55px;
			position: absolute;
			top: 20px;
			left: 9px;
			background: var(--grey-4);
			z-index: 0;
		}
		&:last-child::before {
			content: none;
		}
		.step-pointer {
			width: 20px;
			height: 20px;
			border-radius: 50%;
			color: var(--step-pointer-color);
			border: 1px solid var(--grey-4);
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: 0.5;
			font-weight: 700;
			z-index: 1;
			background-color: var(--step-pointer-color);
			flex-shrink: 0;
		}
	}
	.step-active {
		color: var(--step-progressbar-active);
		font-weight: 700;
		cursor: default;
		.step-pointer {
			padding-bottom: 11px;
			padding-left: 0px;
			background-color: var(--step-progressbar-active);
			border-color: var(--step-progressbar-active);
			font-size: 20px;
		}
	}
	.step-complete {
		cursor: not-allowed;
		.step-pointer {
			color: var(--step-progressbar-active);
			// background-color: $white;
			border-color: var(--step-progressbar-active);
		}
		&:before {
			background-color: var(--step-progressbar-active);
		}
	}
}

.calendar-cmp {
	padding: 16px 20px;
	display: flex;
	flex: 1;
	* {
		border-color: var(--calendar-border) !important;
	}
	.fc {
		flex: 1;
		display: flex;
		flex-direction: column;
		.fc-toolbar {
			margin-bottom: 0.9rem;
			.fc-left,
			.fc-right {
				.fc-button {
					border: none;
					background: transparent;
					color: var(--grey-3);
					&.fc-today-button {
						font-size: 0.875rem;
						color: var(--view-today-active-color);
						border: 1px solid var(--view-today-active-border);
						border-radius: 4px;
						padding: 2px 16px;
					}
					&[disabled] {
						color: var(--view-today-disabled);
						border-color: var(--view-today-disabled) !important;
					}
					&:hover,
					&:focus {
						box-shadow: none;
					}
				}
			}
			h2 {
				font-size: 1.375rem;
				font-weight: 600;
				color: var(--grey-10);
			}
		}
		.fc-view-container {
			flex: 1;
			display: flex;
			.fc-view {
				table {
					height: 100%;
					.fc-head {
						.fc-head-container {
							.fc-row {
								&.row-details {
									z-index: 10;
								}
								.fc-day-header {
									padding: 12px 0;
									span {
										text-transform: uppercase;
										color: var(--grey-5);
									}
								}
							}
						}
					}
					.fc-body {
						.fc-widget-content {
							.fc-day-grid-container {
								height: calc(100vh - 255px) !important;
								overflow: visible !important;
								//height: 100% !important;
								min-height: 100%;
								display: flex;
								.fc-day-grid {
									height: 100%;
									display: flex;
									flex-direction: column;
									.fc-row {
										z-index: initial;
										height: auto !important;
										flex: 1;
										.fc-bg {
											z-index: initial;
											.fc-day {
												background: transparent;
												position: relative;
												height: 100%;
												&.cell-details {
													.detailed-calendar {
														z-index: 10000;
														border-radius: 4px;
														box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
														border: 5px solid var(--detailed-calendar-border) !important;
														box-sizing: border-box;
														position: relative;
														transition: 0.3s all ease;
														overflow: hidden;
														width: 120%;
														left: -10%;
														height: 200%;
														top: -50%;
														color: #444;
														.date {
															height: auto;
															top: -26px;
															bottom: 0;
															margin: auto;
														}
														.scale-back {
															height: 100%;
															overflow: hidden;
														}
														.event-details {
															padding: 2px 0;
															display: block;
														}
														&.no-data {
															background-color: var(--active-calendar-no-data-bg);
															.date,
															.trade-name {
																color: var(--active-calendar-no-data-color);
															}
														}
													}

													&.fc-today {
														.date {
															span {
																line-height: 1.8;
																white-space: nowrap;
															}
														}
													}
												}
												&.fc-other-month {
													opacity: 0;
												}
												.detailed-calendar {
													transition: 0.3s all ease;
													cursor: pointer;
													&.has-data {
														.event-details {
															display: block;
														}
													}
													&.no-data {
														background-color: var(--calendar-no-data-bg);
														.date {
															top: 0;
															color: var(--calendar-no-data-color);
														}
														.event-details {
															display: block;
														}
													}
													.trade-name {
														text-align: center;
														//font-size: 0.45rem;
														font-weight: 600;
														padding-top: 2px;
														//transform: scale(1.8, 1);
													}
													.date {
														top: 0;
														display: flex;
														justify-content: center;
														align-items: center;
														font-size: 22px;
														width: 100%;
														height: 100%;
														position: absolute;
														color: #444;
													}
													.cash-in-flow,
													.cash-out-flow,
													.net-cash-flow {
														font-size: 0.75rem;
														position: absolute;
														bottom: 55px;
														text-align: center;
														width: 100%;
														//transform: scale(1.8, 1);
													}
													.cash-out-flow {
														bottom: 40px;
													}
													.net-cash-flow {
														bottom: 20px;
													}
													.event-details {
														position: absolute;
														bottom: 0;
														left: 0;
														right: 0;
														padding: 2px 0;
														background: rgba(255, 255, 255, 0.8);
														color: #460d0d;
														opacity: 0.8;
														text-align: center;
														font-size: 0.6875rem;
														font-weight: 600;
														display: none;
													}
													&.green {
														background: #ace383 !important;
														.event-details {
															color: #579827;
														}
													}
													&.red {
														background: #ff766a !important;
														.event-details {
															color: #c65331;
														}
													}
													&.yellow {
														background: #ffe58d !important;
														.event-details {
															color: #878d07;
														}
													}
												}
												&.fc-past {
													.detailed-calendar {
														&.green {
															background: rgba(172, 227, 131, 0.5);
														}
														&.red {
															background: rgba(255, 118, 106, 0.5);
														}
														&.yellow {
															background: rgba(255, 229, 141, 0.5);
														}
													}
												}
												&.fc-today {
													.date {
														span {
															background: rgba(0, 0, 0, 0.15);
															border-radius: 50%;
															height: 40px;
															width: 40px;
															line-height: 40px;
															text-align: center;
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.no-data {
	background-color: var(--white);
}
.bg-green-shade-one {
	background-color: #cfed69;
}

.bg-green-shade-two {
	background-color: #b8ed54;
}

.bg-green-shade-three {
	background-color: #9be84d;
}

.bg-green-shade-four {
	background-color: #82e344;
}

.bg-green-shade-five {
	background-color: #63d840;
}

.bg-red-shade-one {
	background-color: #f7c641;
}

.bg-red-shade-two {
	background-color: #ffba5d;
}

.bg-red-shade-three {
	background-color: #ffa25d;
}

.bg-red-shade-four {
	background-color: #ff895d;
}

.bg-red-shade-five {
	background-color: #ff765d;
}

.bg-yellow-shade-one {
	background-color: #f7c641;
}

.bg-yellow-shade-two {
	background-color: #f4df40;
}

.bg-yellow-shade-three {
	background-color: #fcea64;
}

.bg-yellow-shade-four {
	background-color: #f5f57f;
}

.bg-yellow-shade-five {
	background-color: #e8ed69;
}

.text-green-shade-one {
	color: #cfed69;
}

.text-green-shade-two {
	color: #b8ed54;
}

.text-green-shade-three {
	color: #9be84d;
}

.text-green-shade-four {
	color: #82e344;
}

.text-green-shade-five {
	color: #63d840;
}

.text-red-shade-one {
	color: #f7c641;
}

.text-red-shade-two {
	color: #ffba5d;
}

.text-red-shade-three {
	color: #ffa25d;
}

.text-red-shade-four {
	color: #ff895d;
}

.text-red-shade-five {
	color: #ff765d;
}

.text-yellow-shade-one {
	color: #f7c641;
}

.text-yellow-shade-two {
	color: #f4df40;
}

.text-yellow-shade-three {
	color: #fcea64;
}

.text-yellow-shade-four {
	color: #f5f57f;
}

.text-yellow-shade-five {
	color: #e8ed69;
}

.fc-content-skeleton {
	display: none;
}

p {
	margin: 0;
}

.trade-name,
.cash-in-flow,
.net-cash-flow,
.cash-out-flow {
	display: none;
}

.cell-details .trade-name,
.cell-details .cash-in-flow,
.cell-details .cash-out-flow,
.cell-details .net-cash-flow {
	display: block;
}

.detailed-calendar {
	height: 100%;
}
.fc-view {
	z-index: auto !important;
	> table {
		z-index: auto !important;
	}
}
.event-calender {
	.detailed-calendar {
		z-index: 1 !important;
	}
}

.form-password-input {
	position: relative;

	.form-control {
		padding-right: 4rem;
	}

	.form-icon {
		position: absolute;
		top: 53%;
		transform: translateY(-50%);
		right: 20px;
		z-index: 0;
		cursor: pointer;
	}
}

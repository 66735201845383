table {
	display: table;
	width: 100%;
	overflow-x: auto;
}

.default_FsPivotTable {
	// overflow: auto;
	padding: 1.5rem;
	flex: 1;
	.pvtUi {
		font-family: 'Source Sans Pro', sans-serif;
		font-size: 16px;
		tbody {
			tr {
				td {
					&.pvtOutput {
						.pvtTable {
							font-family: inherit;
							thead {
								tr {
									th {
										text-align: center !important;
										font-size: 0.875rem;
										text-transform: uppercase;
										color: var(--grey-5);
										background: $table-header-bg;
										font-weight: bold;
									}
								}
							}
							tbody {
								tr {
									th {
										background: $table-header-bg;
										text-transform: uppercase;
										font-size: 0.875rem;
										&.pvtRowLabel,
										&.pvtTotalLabel {
											text-align: left;
											padding-left: 14px;
											color: var(--grey-5);
										}
									}
									td {
										padding: 14px;
										font-size: 0.875rem;
										text-align: center;
										font-weight: bold;
										color: var(--black);
										cursor: pointer;
										background-color: var(--grey-15);
									}
								}
							}
						}
						.main-svg {
							background-color: inherit !important;
						}
						.legend .bg {
							fill: var(--pivot-svg-bg-color) !important;
						}
					}
				}
			}
		}
	}
}

.pvtAxisContainer,
.pvtVals {
	background: transparent !important;
	// max-height: 100px !important;
}

.pvtRowOrder,
.pvtColOrder {
	display: none !important;
}

.pvtRenderers,
.pvtVals {
	width: 100px;
	max-width: 100px;
	border: 1px solid #a2b1c6;
}

.pvtDropdownCurrent,
.pvtRenderers {
	width: 100px !important;
	padding-right: 10px;
}

.pvtVals .pvtDropdown {
	width: 100px;
	padding-right: 15px;
	&:last-child {
		display: none; // Pivot Notional dropdown
	}
}

.pvtVals {
	text-align: left !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}

.pvtDropdownValue {
	white-space: normal;
	color: var(--pivot-table-dropdown-value);
}

.pvtDropdownActiveValue {
	color: #2a3f5f;
}

.pvtDropdownCurrent {
	color: white;
	background-color: var(--primary) !important;
	font-weight: 600;
}

.pvtDropdownIcon {
	color: white !important;
	font-weight: 600;
}

table,
tr,
td,
th {
	border-color: var(--pivot-table-border-color) !important;
}

td.pvtAxisContainer {
	li {
		padding: 2px 6px !important;
		span.pvtAttr {
			font-size: 13px;
			background: var(--pvtAttrDefault);
			border-color: var(--pvtAttrDefaultBorder);
			color: var(--pvtAttrDefaultColor);
		}
	}
	li[data-id^='Source'] span {
		background-color: var(--pvtAttrBlue) !important;
	}
	li[data-id^='Use'] span {
		background-color: var(--pvtAttrYellow) !important;
	}
}

.pvtFilterBox {
	position: fixed !important;
	background-color: var(--pvtAttrDefault) !important;
	color: var(--pvtAttrDefaultColor);
	.pvtOnly {
		color: var(--checkmark-green) !important;
	}
}

.hideRowTotal {
	thead > tr > th.pvtTotalLabel,
	.rowTotal,
	.pvtGrandTotal {
		display: none;
	}
}

.hideColTotal {
	tbody > tr > th.pvtTotalLabel,
	tbody > tr > td.pvtTotal,
	.colTotal,
	.pvtGrandTotal {
		display: none;
	}
}

.pvtDropdownMenu {
	background-color: var(--pivot-table-dropdown-bg) !important;
}

.pvtOutput {
	textarea {
		background-color: var(--pvtAttrDefault);
		color: var(--pvtAttrDefaultColor);
	}
}

.pvtCheckContainer p.selected {
	background: var(--primary) !important;
	color: var(--nav-submenu-active-color);
}

//DMN viewer related css
.dmn-decision-table-container .tjs-table tbody tr:nth-child(2n) {
	background-color: transparent !important;
}

.dms-select-options {
	background: var(--dropdown-menu-bg) !important;
	color: var(--dropdown-item-color) !important;
	.option.active {
		color: var(--dropdown-item-hover-color) !important;
		background: var(--dropdown-item-hover-bg) !important;
	}
}
.abcm-analytics {
	.default_FsPivotTable {
		padding-right: 0;
		padding-left: 0;
	}
}

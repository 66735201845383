.account-form-wrapper,
.account-form-wrapper-wide {
	margin: 0 auto;
	.form-group {
		margin-top: 1rem;
		label {
			font-size: 0.875rem;
			color: var(--text-color-444);
			margin-bottom: 0.25rem;
		}
		.form-control {
			padding: 10px 0 10px 16px;
			line-height: normal;
			height: auto;
			font-size: 0.875rem;
			border-color: var(--text-color-444);
		}
		.dropdown {
			.dropdown-toggle {
				padding: 9px 32px 10px 12px;
				font-size: 0.875rem;
			}
		}
	}
}

.account-form-wrapper {
	width: 350px;
}
.account-form-wrapper-wide {
	width: 550px;
}

.right-part {
	.loader-wrapper:after {
		z-index: 1;
	}
}

.left-part {
	&.loader-wrapper:after {
		z-index: 1;
	}
}

.accordion-menu {
	padding: 15px 20px;
}
.sub-menu-border-bottom {
	border-bottom: 1px solid var(--border-color);
}
.org-info-form {
	input {
		font-size: 14px;
		padding-left: 12px;
		padding-right: 12px;
	}
}
.add-new-entities {
	.modal-content {
		@extend.rounded-sm;
	}
}

.legal-entity-detail-popover {
	.custom-popover-basic {
		min-width: 580px;
		max-width: 580px;
		max-height: 580px;
		height: 500px;

		.custom-popover-content {
			max-height: 580px;
			overflow: auto;
		}
		.arrow {
			&:before,
			&:after {
				display: none;
			}
		}
	}
	.list-group-item {
		border-bottom: 1px solid var(--rt-border);
		color: var(--popover-text-color);
	}
}

.depos-label {
	color: var(--text-color-444);
}
.depos-text {
	color: var(--text-color-666);
}
.depos-list-section {
	.list-group-item {
		border-color: var(--menu-item-border-color);
	}

	.dropdown-menu {
		transform: translate3d(-30px, 27px, 0px) !important;
	}
	.dropdown.v-dots {
		.dropdown-menu {
			left: -100px !important;
			transform: translate3d(-35px, 36px, 0px) !important;
		}
	}
}
.subtree-row {
	position: relative;
	margin-bottom: 16px;
	&:after {
		border-bottom: 1px solid var(--border-color);
		content: '';
		position: absolute;
		width: 93%;
		bottom: 0;
		right: -16px;
	}
}
.delete-icon {
	color: var(--gray-light);
}
.section-header {
	padding: 6px 20px;
	font-size: 12px;
	font-weight: $font-weight-bold;
	background-color: var(--section-header-bg);
	margin-bottom: 10px;
}
.select-custom-dropdown {
	&:hover {
		color: $white !important;
	}
}
.select-legal-entity-modal {
	.filter-list {
		padding: 12px 20px;
		.filter-list-items {
			span {
				width: 27px;
				height: 27px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: 600;
				&.active {
					background-color: $blue;
					color: $white;
				}
				&:hover {
					background-color: $blue;
					color: $white;
				}
			}
		}
	}
	.ag-root-wrapper {
		border: 0 !important;
		border-radius: 0 !important;
	}
	// .modal-content {
	// 	max-height: 592px;
	// }
}

.myclients-relationship {
	.rbt {
		.rbt-input-multi {
			font-size: 14px !important;
			padding: 10px !important;
			.rbt-token {
				color: $white;
				background: $blue;
			}
			.rbt-token-active {
				background: $blue;
			}
		}
	}
}

.sortableTree {
	min-height: 300px;
	max-height: 300px;

	.rst__rowSubtitle {
		position: relative !important;
		top: -36px !important;
	}

	.rst__rowContents {
		border: none;
		box-shadow: none;
		background: transparent;
	}
	.errorBorder {
		border-color: red;
	}
}
